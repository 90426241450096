import { Component, OnInit, Input, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Lookups, Document, Location, DocumentType } from '~models/traveldoc/index';
import { ConfigurationService, DataShimService } from '~common/services/index';
import { ItemBase } from '~common/components/base/itembase';
import { CustomValidators } from '~common/validators/custom-validators';
import moment, { Moment } from 'moment';


@Component({
  selector: 'document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent extends ItemBase implements OnInit {

  lookups: Lookups;
  @Input() document: Document;

  @Output() documentRemoved = new EventEmitter<Document>();

  @ViewChild('documentExpiry', { static: false }) datepickerInput: ElementRef;

  private passport: DocumentType;
  private visa: DocumentType;

  // convenience getter for easy access to form fields
  get controls() { return this.form.controls; }

  public readonly minDate: Date;

  constructor(configurationService: ConfigurationService,
    private dataShimService: DataShimService) {
    super();
    this.lookups = configurationService.configuration.lookups;
    this.form = new FormGroup({});
    this.passport = configurationService.configuration.lookups.documentTypes.filter(dt => dt.name.toLowerCase() === "passport")[0];
    this.visa = configurationService.configuration.lookups.documentTypes.filter(dt => dt.name.toLowerCase() === "visa")[0];
    this.minDate = configurationService.configuration.minDate;
  }


  ngOnInit() {
    const typeControlName: string = this.getControlName('type');
    const issuedByControlName: string = this.getControlName('issuedBy');
    const nationalityControlName: string = this.getControlName('nationality');
    const expiryControlName: string = this.getControlName('expiry');

    this.form.addControl(typeControlName, new FormControl('',  CustomValidators.objectSelectedValidator));
    this.form.addControl(issuedByControlName, new FormControl('', CustomValidators.objectSelectedValidator));
    this.form.addControl(nationalityControlName, new FormControl('', CustomValidators.objectSelectedValidator));
    this.form.addControl(expiryControlName, new FormControl('', CustomValidators.objectSelectedValidator));

    this.controls[typeControlName].setValue(this.document?.documentType?.name);
    this.controls[issuedByControlName].setValue(this.document.issuingCountry);
      this.controls[nationalityControlName].setValue(this.document.nationality);
      if (typeof this.document.expiry == "string") {
          this.document.expiry = moment(this.document.expiry);
      }
    this.controls[expiryControlName].setValue(this.document.expiry);

    this.controls[typeControlName].valueChanges.subscribe((value: DocumentType) => {
      this.document.documentType = value;
    });

    this.controls[issuedByControlName].valueChanges.subscribe((value: Location) => {
      this.checkWrapper(value, () => {
        this.document.issuingCountry = value
        if (this.document.documentType === undefined) {
          if (this.controlPrefix === 0) {
            this.document.documentType = this.passport;
          }
          else {
            this.document.documentType = this.visa;
          }
        }
        if (this.document.nationality === undefined) {
          this.document.nationality = this.dataShimService.mitigateNationality(value);
        }
      });
    });

    this.controls[nationalityControlName].valueChanges.subscribe((value: Location) => {
      this.checkWrapper(value, () => {
        this.document.nationality = value;
        if (this.document.issuingCountry === undefined) {
          this.document.issuingCountry = this.dataShimService.mitigateIssuingCountry(value)
        }
      });
    });

    this.controls[expiryControlName].valueChanges.subscribe((value: Moment) => {
      this.checkWrapper(value, () => {
        this.document.expiry = value;
        //if (this.datepickerInput) {
        //  this.focusNext(this.datepickerInput.nativeElement);
        //}
      });
    });

  }

  remove($event: MouseEvent) {
    $event.preventDefault();
    if (this.documentRemoved) { this.documentRemoved.emit(this.document); }
  };



}
