import { Component, OnInit, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { TravelDocAPIService, ErrorService, QueryStringHelperService, DataService, ConfigurationService } from '../../../../common/services';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { DocumentsComponent } from '../documents/documents.component';
import { YourTripComponent } from '../your-trip/your-trip.component';
import { Subscription } from 'rxjs';
import { SegmentFormType } from '../../../../../models/enum/segmentformtype';
import { TranslateService } from '@ngx-translate/core';
import { ClientTranslateService } from '../../../../common/services/i18n/client.translate.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    readonly clientTranslateService: ClientTranslateService;

    termsUrl: string;
    privacyUrl: string;

    form: FormGroup;
    @ViewChild(DocumentsComponent, { static: false }) documentsComponent: DocumentsComponent;
    @ViewChild(YourTripComponent, { static: false }) yourTripComponent: YourTripComponent;
    @Output() querystringChanged: EventEmitter<string> = new EventEmitter<string>();

    constructor(private travelDocAPIService: TravelDocAPIService,
        private router: Router,
        public dataService: DataService,
        private configurationService: ConfigurationService,
        private errorService: ErrorService,
        private quryStringHelperService: QueryStringHelperService,
        private cd: ChangeDetectorRef,
        private translateService: TranslateService
    ) {
        this.termsUrl = this.configurationService.configuration.termsUrl;
        this.privacyUrl = this.configurationService.configuration.privacyUrl;
        this.clientTranslateService = this.translateService as ClientTranslateService;
    }

    queryString: string;
    subscriber: Subscription;


    ngOnInit() {
        try {
            this.form = new FormGroup({});
            this.subscriber = this.form.valueChanges.subscribe(async () => {
                let updatedLibraryLink: string;
                let updatedQueryLink: string;
                if (this.form.valid) {
                    updatedLibraryLink = await this.quryStringHelperService.getRawQueryStringFromPassenger(this.dataService.passenger);
                    updatedQueryLink = await this.quryStringHelperService.getQueryStringFromPassenger(this.dataService.passenger);
                }
                if (updatedQueryLink) {
                    this.queryString = `https://${this.configurationService.configuration.baseUrl}?${updatedLibraryLink}&results=true`;
                } else {
                    this.queryString = ''
                }
                this.quryStringHelperService.queryStringChanged(updatedLibraryLink);
                this.cd.detectChanges();
            });

            if (this.quryStringHelperService.showResults) {
                this.router.navigate(['results']);
            }
        } catch (e) {
            window.appInsights?.trackException(e);
            this.clientTranslateService.get('passengerview.home.error.initailisingPage')
                .pipe(take(1))
                .subscribe((message: string) => {
                    this.errorService.addError(new Error(message));
                });
        }
    }

    ngAfterViewInit() {
        this.form.addControl('yourTripComponent', this.yourTripComponent.form);
        this.form.addControl('documentsComponent', this.documentsComponent.form);
    }

    async checkPassenger(): Promise<void> {

        if (this.validateControls(this.form)) {

            try {
                this.dataService.passenger.questions = await this.travelDocAPIService.getInterviewAsync(this.dataService.passenger);
            } catch (e) {
                window.appInsights?.trackException(e);
                this.errorService.addError(new Error(await this.clientTranslateService.get('passengerview.home.error.gettingInterview').toPromise()))
                return;
            }

            try {
                if (this.dataService.passenger.questions && this.dataService.passenger.questions.length !== 0) {
                    this.router.navigate(['questions']);
                }
                else {
                    this.dataService.results = await this.travelDocAPIService.checkPassengerAsync(this.dataService.passenger);
                    if (this.dataService.results) {
                        this.router.navigate(['results']);
                    }
                }
            } catch (e) {
                window.appInsights?.trackException(e);
                this.errorService.addError(new Error(await this.clientTranslateService.get('passengerview.home.error.gettingResults').toPromise()))
            }
        }
    };

    ngOnDestroy() {
        this.subscriber.unsubscribe();
        this.subscriber = null;
    }

    private validateControls(form: FormGroup): boolean {
        form.markAllAsTouched();
        //this.debugForms();
        return form.valid
    }



    private debugForms() {
        const yt = this.form.controls["yourTripComponent"] as FormGroup;

        const fOw = yt.controls[SegmentFormType.OneWay.toString()] as FormGroup;
        const fRe = yt.controls[SegmentFormType.Return.toString()] as FormGroup;
        const fMs = yt.controls[SegmentFormType.MultiSegment.toString()] as FormGroup;


        console.log(`Form overall status = '${this.form.status}' is valid = '${this.form.valid}'`);
        console.log(this.form.controls);

        if (fOw) {
            console.log('One way form loaded');
            console.log(`One way form status = '${fOw.status}' is valid = '${fOw.valid}'`);
            console.log(fOw.controls);
        }
        if (fRe) {
            console.log('Return form loaded');
            console.log(`Return form status = '${fRe.status}' is valid = '${fRe.valid}'`);
            console.log(fRe.controls);
        }
        if (fMs) {
            console.log('Multi segment form loaded');
            console.log(`Multi segment form status = '${fMs.status}' is valid = '${fMs.valid}'`);
            console.log(fMs.controls);
        }
    }

}
