import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LoaderService } from '../utils/loader.service';
@Injectable({
    providedIn: 'root'
})
export class HttploaderinteceptorService {

    constructor(private loaderService: LoaderService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const ajaxRequests: Array<string> = ['getinterview', 'checkpassenger', 'getresult','submitfeedback'];
        // filter callback function
        const urlContains = (url: string): boolean => {
            for (let i = 0; i < ajaxRequests.length; i++) {
                if (url.toLowerCase().indexOf(ajaxRequests[i]) > -1) {
                    return true;
                }
            }
            return false;
        }

        // Only show the loader for ajax requests to methods in the array
        if (urlContains(request.url)) {
            return next.handle(request)
                .pipe(
                    tap(() => {
                        this.loaderService.show()
                    }),
                    finalize(() => this.loaderService.hide()));
        }
        else {
            return next.handle(request);
        }
    }

}
