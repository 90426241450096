import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PageType } from '../../../../../models/enum/pagetype';
import { User } from '../../../../../models/configuration/user';
import { ConfigurationService, QueryStringHelperService } from '../../../services';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'site-header',
    templateUrl: './site-header.component.html',
    styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent {
    @Input() page: PageType = PageType.PassengerView;
    @Input() showLanguageSelection = false;
    //get libraryViewUrl(): SafeUrl {
    //    let url = `https://${this.baseURL}/Library/Rules${this.queryString}`
    //    console.log(url)
    //    return this.sanitizer.bypassSecurityTrustUrl(url);
    //}
    //get passengerViewUrl(): SafeUrl {

    //    return this.sanitizer.bypassSecurityTrustUrl(`https://${this.baseURL}${this.queryString}`);
    //}
    pageTypes = PageType;
    switchState: boolean;
    isAuthenticated: boolean;
    baseURL: string;
    queryString = "";
    subscriber: Subscription;
    loginAction: SafeUrl;


    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );


    constructor(private breakpointObserver: BreakpointObserver,
        public configurationService: ConfigurationService,
        private queryStringHelper: QueryStringHelperService,
        private cd: ChangeDetectorRef,
        private sanitizer: DomSanitizer
    ) {
        this.baseURL = this.configurationService.configuration.baseUrl;
        this.isAuthenticated = this.configurationService.configuration.user.authenticated;
        this.switchState = undefined;
        this.loginAction = this.sanitizer.bypassSecurityTrustUrl((this.isAuthenticated) ? this.formatPath('Endpoints/Logout') : this.formatPath('Library/Rules'));
    }

    ngOnInit() {
        this.setShowSwitch();

        this.subscriber = this.queryStringHelper.changeEmitted$.subscribe((queryString: string | undefined) => {
            this.queryString = (queryString !== undefined) ? `?${queryString}` : '';
            this.cd.detectChanges();
        });
    }

    private setShowSwitch() {
        const user: User = new User();
        user.claims = this.configurationService.configuration.user.claims;
        if (this.isAuthenticated && user && user.isApproved()) {
            switch (this.page) {
                case PageType.LibraryView: { this.switchState = true; } break;
                case PageType.PassengerView: { this.switchState = false; } break;
                default: { this.switchState = undefined; } break;
            }
        }
        else {
            this.switchState = undefined;
        }
    }


    //handleAccountEvent() {
    //  if (this.isAuthenticated) {
    //    // Log out the user
    //    window.location.href = this.formatPath('Endpoints/Logout');
    //  }
    //  else {
    //    window.location.href = this.formatPath('Library/Rules');
    //  }
    //}

    getSelected(pageType: PageType): HTMLElement {

        let HTML: HTMLElement = undefined;

        if (pageType === this.page) { HTML = document.createElement('span'); HTML.className = "sr-only"; }
        return HTML;

    }

    getLink(pageType: PageType): string {
        let path = './';
        switch (pageType) {
            case PageType.LibraryView: { path = 'library/rules'; } break;
            case PageType.PassengerView: { path = ''; } break;
            case PageType.Integration: { path = 'info/integration'; } break;
            case PageType.Widget: { path = 'info/widget'; } break;
            case PageType.Android: { path = 'info/android'; } break;
            case PageType.Contact: { path = 'info/contact'; } break;
            default: {
                window.appInsights?.trackException({ exception: new Error(`Error building link in site header component. Unexpected page name '${pageType}'`) });
            } break;
        }
        return this.formatPath(path);
    }

    readonly pageRules: string;


    formatPath(path: string) {
        return `https://${this.baseURL}/${path}`;
    }


}
