import { Component, OnInit, Input, EventEmitter, Output, ViewChild,  } from '@angular/core';
import { ConfigurationService } from '~common/configuration/configuration.service';
import { Lookups, Segment, Location } from '~models/traveldoc';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FocusNextBase } from '~common/components/base/focusnextbase';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { CustomValidators } from '~common/validators/custom-validators';
import { map, shareReplay } from 'rxjs/operators';
import * as moment from 'moment';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/


@Component({
  selector: 'return-trip',
  templateUrl: './return-trip.component.html',
  styleUrls: ['./return-trip.component.scss']
})

export class ReturnTripComponent extends FocusNextBase implements OnInit {
  form: FormGroup;
  lookups: Lookups;
  @Input() parentForm: FormGroup;
  @Input() segments: Array<Segment>;

  @ViewChild('departureDate', { static: false }) departureDate;
  @ViewChild('returnDate', { static: false }) returnDate;
  @ViewChild('return', { static: false }) returnDateCalendar;

  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
  @Output() originChanged: EventEmitter<Location> = new EventEmitter<Location>();

  readonly ctrl_departureDate: string = "departureDate";
  readonly ctrl_returnDate: string = "returnDate";
  readonly ctrl_departureLocation: string = "departureLocation";
  readonly ctrl_arrivalLocation: string = "arrivalLocaiton";
  public readonly minDate: Date;
  public readonly maxDate: Date;
  readonly dateInterval: number;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver,
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService) {
    super();
    this.lookups = this.configurationService.configuration.lookups;
    this.minDate = configurationService.configuration.minDate;
    this.maxDate = configurationService.configuration.maxDate;
    this.dateInterval = configurationService.configuration.segmentDatesDefault;
  }

  get returnMinDate()  {
    if(!this.segments && this.segments.length){
      return this.minDate;
    }
    else{
      if(moment.isMoment(this.segments[0].departureDate)){
        return (this.segments[0].departureDate.toDate() > this.minDate)?this.segments[0].departureDate.toDate():this.minDate;     
      }
      else{
        return (this.segments[0].departureDate > this.minDate)?this.segments[0].departureDate:this.minDate;
      }
    }
  }

  get controls() { return this.form.controls; }

  ngOnInit() {
    this.form = this.formBuilder.group({
      departureLocation: ['', CustomValidators.objectSelectedValidator],
      arrivalLocaiton: ['', CustomValidators.objectSelectedValidator]
    });

    this.form.addControl('departureDate', new FormControl(moment(this.segments[0].departureDate), CustomValidators.objectSelectedValidator));
    this.controls[this.ctrl_departureDate].valueChanges.subscribe((value: moment.Moment) => {
      if (typeof value !== "string") {
        this.segments[0].departureDate = value;
        if(this.segments[0].departureDate > this.segments[1].departureDate){
          const newDate = moment(value).add(this.dateInterval,'d').toDate();
          this.controls[this.ctrl_returnDate].setValue(newDate);
        }
      }
    });

    this.controls[this.ctrl_departureLocation].valueChanges.subscribe((value: Location) => {
      if (typeof value !== "string") {
        if (this.originChanged) {
          this.originChanged.emit(value);
        }

        this.segments[0].origin = value;
        if (this.segments.length > 1) {
          this.segments[1].destination = value;
        }
      }
    });

    this.controls[this.ctrl_arrivalLocation].valueChanges.subscribe((value: Location) => {
      if (typeof value !== "string") {
        if (this.segments.length > 1) {
          this.segments[1].origin = value;
        }
        this.segments[0].destination = value;
      }
    });

    this.form.addControl('returnDate', new FormControl(moment((this.segments.length > 1) ? this.segments[1].departureDate : new Date()), CustomValidators.objectSelectedValidator));
    this.controls[this.ctrl_returnDate].valueChanges.subscribe((value: moment.Moment) => {
      if (typeof value !== "string") {
        if (this.segments.length > 1) {
          this.segments[1].departureDate = value;
        }
      }
    });
  }
}
