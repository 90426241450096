import { Rule } from '../traveldoc/rule';

export class ConstraintRule {
    title: string;
    text: string;
    appliesTo: string;
    appliesToText: string;
    rule: Rule;

    toFeedbackJSON() {
        return {
            ruleName: this.title,
            applicationMessage: this.text,
            nationalies: (this.rule && this.rule.nationalityCodes) ? this.rule.nationalityCodes.toString() : 'no nationalities',
            tags: (this.rule && this.rule.tags) ? this.rule.tags.map((t) => t.description).toString() : 'no tags',
        }
    }
}
