import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FeedbackMessage } from '../../../../../models/traveldoc';
import { TravelDocAPIService } from '../../../../common/services';

enum FormState {
  New = 1,
  Submitted = 2
}

@Component({
  selector: 'feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})


export class FeedbackDialogComponent implements OnInit {
  public FormState = FormState;
  public formState: FormState;
  constructor(public dialogRef: MatDialogRef<FeedbackDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public feedbackMessage: FeedbackMessage,
    private traveldocAPIService: TravelDocAPIService) {
    this.formState = FormState.New;
  }

  ngOnInit(): void {
  }

  onCloseForm(): void {
    this.dialogRef.close();
  }

  onSubmitClick(): void {

    console.log(this.feedbackMessage);

    this.feedbackMessage.description = this.encodeHTMLEntities(this.feedbackMessage.description);
    this.feedbackMessage.name = this.encodeHTMLEntities(this.feedbackMessage.name);

    this.traveldocAPIService.submitPassengerViewFeedback(this.feedbackMessage)
      .then(() => this.formState = FormState.Submitted)
      .catch(() => this.dialogRef.close());
  }

  private encodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    textArea.innerText = text;
    let encodedOutput = textArea.innerHTML;
    let arr = encodedOutput.split('<br>');
    encodedOutput = arr.join('\n');
    return encodedOutput;
}

}
