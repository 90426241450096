import { AbstractControl, Validators, ValidationErrors } from '@angular/forms';

export class CustomValidators extends Validators {

  static objectSelectedValidator(control: AbstractControl): ValidationErrors | null {
    let returnValue = null;
    const requiredValidator = Validators.required;
    returnValue = requiredValidator(control);
    if (returnValue !== null) return returnValue;
    returnValue = (control.value && typeof control.value) !== "string" ? undefined : { required: true };
    return returnValue;
  }
 
}

