import { ResultStatus } from '../enum/resultstatus';
import { Link } from '.';
import { RuleMessage } from './rulemessage';
import { DestinationDocument } from './destinationdocument';

export class CheckResult {
  iVisaLink: string;
  status: ResultStatus;
  level1Messages: Array<RuleMessage>;
  level2Messages: Array<RuleMessage>;
  destinationDocuments: Array<DestinationDocument>
  links: Array<Link>
  segmentOrdinal: number;
}
