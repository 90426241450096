import { Component } from '@angular/core';
import { QuestionBase } from '../base/questionbase';

@Component({
  selector: 'question-document-type',
  templateUrl: './question-document-type.component.html',
  styleUrls: ['./question-document-type.component.scss']
})
export class QuestionDocumentTypeComponent extends QuestionBase{

  constructor() {
    super();
 }
}
