import { Component, Input, ViewChildren, QueryList } from '@angular/core';
import { Document, Segment } from '../../../../../models/traveldoc';
import { FormGroup } from '@angular/forms';
import { DocumentComponent } from '../document/document.component';
import { ConfigurationService, DataShimService } from '../../../../common/services';
import { addRemove } from '../../../../common/animations/animations';
import moment from 'moment';

@Component({
  animations: [
    addRemove
  ],
  selector: 'documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent {
  @Input() documents: Array<Document>;
  @Input() segments: Array<Segment>;
  form: FormGroup;

  @ViewChildren(DocumentComponent) documentComponents: QueryList<DocumentComponent>;

  constructor(private configurationService: ConfigurationService,
    private dataShimServive: DataShimService) {
    this.form = new FormGroup({});
  }

  ngAfterViewInit() {
    // This needs to be in AfterViewInit as the childcomponents are not
    // available until this point
    this.documentComponents.changes.subscribe(() => {
      this.BindChildForms();
    })
    this.BindChildForms();
  }

  private addDocumentWithDefault(defaultDocumentType: string |undefined){
    const initialSegment = this.segments[0];
    // Get the passport from the list of
    // this needs to be done like this
    // in case the language is not English
    let documentType = undefined;
    if(defaultDocumentType){
        documentType = this.configurationService.configuration.lookups.documentTypes.filter(dt => dt.name.toLowerCase() === defaultDocumentType)[0];
    }
    const document = new Document();
    document.documentType = documentType;
    if (initialSegment.destination) {
      document.issuingCountry = this.dataShimServive.mitigateIssuingCountry(this.segments[0].destination);
    }

    if (initialSegment.origin) {
      const nationalityResult = this.configurationService.configuration.lookups.nationalities.filter(nat => nat.countryICAO === initialSegment.origin.countryICAO);
      if (nationalityResult.length) {
        document.nationality = nationalityResult[0];
      }
    }
    document.expiry = moment().add(1, 'years');
    this.documents.push(document);
  }

  addDocument() {
    this.addDocumentWithDefault(undefined);
  }

  addResidencePermit(){
    this.addDocumentWithDefault('residencepermit')
  }

  addSeamanBook(){
    this.addDocumentWithDefault('seamanbook')
  }

  addVisa(){
    this.addDocumentWithDefault('visa');
  }

  removeDocument(document: Document) {
    // Remove all the document forms as they are re-added by the
    // documentComponents changes binding
    for (let i: number = this.documents.length - 1; i > -1; i--) {
      this.form.removeControl(this.getName(i));
    }
    this.documents.splice(this.documents.indexOf(document), 1);
  }

  private BindChildForms() {
    this.documentComponents.forEach((instance, index) => {
      this.form.addControl(this.getName(index), instance.form);
    });
  }

  getName(index: number): string {
    return `document${index}`;
  }

}
