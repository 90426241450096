import { Component } from '@angular/core';
import { ConfigurationService, QueryStringHelperService } from '../../../common/services';
import { PageType } from '../../../../models/enum/pagetype';
import { config } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  termsUrl: string;
  privacyUrl: string;
  pageTypes = PageType;

  constructor(private configurationService: ConfigurationService) {
    this.termsUrl = configurationService.configuration.termsUrl;
    this.privacyUrl = configurationService.configuration.privacyUrl;
  }
}
