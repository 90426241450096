import { RuleCategory } from '../enum/rulecategory';
import { retry } from 'rxjs/operators';

export class PriorityRule {
  constructor(ruleCategory: RuleCategory, content: string) {
    this.ruleCategory = ruleCategory;
    this._content = content;
  }

  private readonly ruleCategory: RuleCategory;
  private readonly _content: string;

  get title(): string {
    switch (this.ruleCategory) {
      case RuleCategory.AcceptedDocuments: return "Accepted Documents"; break;
      case RuleCategory.Constraint: return "Constraint"; break;
      case RuleCategory.DocumentRequired: return "Document Required"; break;
      case RuleCategory.NoAcceptedDocuments: return "No Accepted Documents"; break;
      case RuleCategory.OnwardTravel: return "Onwards Travel"; break;
      case RuleCategory.PassportValidity: return "Passport Validity"; break;
      case RuleCategory.VisaWaiver: return "Visa Waiver"; break;
      default: break;
    }
  };

  get content(): string {
    return this._content;
  }
  
}
