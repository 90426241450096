import { Component, OnInit } from '@angular/core';
import {
  TravelDocAPIService,
  ConfigurationService
} from '../../../../common/services';

import { InterviewAnswer } from '../../../../../models/traveldoc';
import { AbstractAutocompleteComponent } from '../../../../common/src/lib/abstract.autocomplete/abstract.autocomplete.component';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';


@Component({
  selector: 'questionAutocomplete',
  templateUrl: './question.autocomplete.component.html',
  styleUrls: ['./question.autocomplete.component.scss']
})
export class QuestionAutocompleteComponent extends AbstractAutocompleteComponent<InterviewAnswer> implements OnInit {


  constructor(travelDocAPIService: TravelDocAPIService,
    configurationService: ConfigurationService) {
    super(travelDocAPIService, configurationService);
  }

  ngOnInit() {
    this.filteredOptions = this.parentForm.controls[this.controlName].valueChanges
      .pipe(
        startWith(''),
        map(input => typeof input === 'string' ? input : ''),
        map(value => this.filter(value))
      );
  }

  private filter(value: string): Array<InterviewAnswer> {
    const filterValue = (value) ? value.toLowerCase() : '';
    return this.items.filter(option => (option && option.value) ? option.value.toLowerCase().includes(filterValue) : false);

  }

  format = (answer: InterviewAnswer) => (answer) ? answer.value : null;

  onSelected(input: MatAutocompleteSelectedEvent) {
    const answer: InterviewAnswer = input.option.value;
    //this.selectedItem = answer;
    //this.focusNext(this.autoCompleteInput.nativeElement);
    if (this.changedEvent) {
      this.changedEvent.emit(answer);
    }
  }

}
