import { Component, OnInit, Input } from '@angular/core';
import { DisplayGroup } from '../../../../../models/display/displaygroup';

@Component({
  selector: 'segment-header',
  templateUrl: './segment-header.component.html',
  styleUrls: ['./segment-header.component.scss']
})
export class SegmentHeaderComponent<T> implements OnInit {

  @Input() displayGroup: DisplayGroup<T>;

  ngOnInit() {}

}
