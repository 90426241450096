import { Injectable } from '@angular/core';
import { InterviewQuestion, CheckResults, Passenger, Segment, Document, DocumentType, DocumentFormat, LibraryResult } from '../../../../models/traveldoc';
import { JavaScriptValues } from '../../../../models/configuration/javasciptvalues';
import { DataService } from '../utils/data.service';
import * as moment from 'moment';
import { InputValues } from '../../../../models/library/inputvalues';
import { User } from '../../../../models/configuration/user'
import { Data } from '@angular/router';

const defaultDocFormatId = 5; // Biometric
const defaultDocumentType = 'passport'; //passport

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    isLibrary: boolean = false;
    configuration: JavaScriptValues;
    results: CheckResults;
    questions: Array<InterviewQuestion>;
    antiForgeryToken: string;
    inputValues: InputValues;
    libraryResult: LibraryResult;

    constructor(
        private dataService: DataService) {
        this.results = null;
        this.questions = new Array<InterviewQuestion>();

        const bootData = window['td'];

        this.configuration = this.bindConfiguration(bootData);

        this.validateConfiguration(this.configuration);
        this.bindDataService(bootData, this.dataService);
        this.bindInputValues(bootData);
    }
    private bindDataService(jsonJsValues, dataService: DataService) {
        dataService.passenger = this.getPassenger(jsonJsValues.passenger);
        dataService.results = jsonJsValues.results;
    }

    private bindInputValues(jsonJsValues) {
        this.inputValues = this.getInputValues(jsonJsValues.inputValues);
        this.libraryResult = jsonJsValues.libraryResult;
    }



    private bindConfiguration(jsonJsValues): JavaScriptValues {
        const returnValue = new JavaScriptValues();
        returnValue.baseUrl = jsonJsValues.baseUrl;
        returnValue.tagManagerKey = jsonJsValues.tagManagerKey;
        returnValue.aiConfig = jsonJsValues.aiConfig;
        returnValue.infoPagesUrl = jsonJsValues.infoPagesUrl;
        returnValue.privacyUrl = jsonJsValues.privacyUrl;
        returnValue.termsUrl = jsonJsValues.termsUrl;
        returnValue.selectedCulture = jsonJsValues.selectedCulture;
        returnValue.segmentDatesDefault = jsonJsValues.segmentDatesDefault;
        returnValue.user = new User(); // Required to keep the object prototype
        returnValue.user.authenticated = jsonJsValues.user.authenticated;
        returnValue.user.claims = jsonJsValues.user.claims;

        returnValue.lookups.countryGroups = jsonJsValues.lookups.countryGroups;
        returnValue.lookups.documentFormats = jsonJsValues.lookups.documentFormats;
        returnValue.lookups.documentTypes = jsonJsValues.lookups.documentTypes;
        returnValue.lookups.issuingAuthorities = jsonJsValues.lookups.issuingAuthorities;
        returnValue.lookups.nationalities = jsonJsValues.lookups.nationalities;
        returnValue.lookups.nationalitiesOnly = jsonJsValues.lookups.nationalitiesOnly;

        returnValue.selectedCulture = jsonJsValues.selectedCulture;
        returnValue.skin = jsonJsValues.skin;
        this.antiForgeryToken = jsonJsValues.antiForgeryToken;
        return returnValue;
    }

    private validateConfiguration(configuration: JavaScriptValues) {
        if (this.isNullOrWhitespace(configuration.baseUrl)) {
            throw new Error(`TravelDoc Configuration error baseURL is missing'`);
        }


        if (!configuration.lookups) {
            throw new Error(`Configuration error lookups data was not loaded'`);
        }

        if (!configuration.lookups.documentFormats) {
            throw new Error(`Configuration error documentFormats data was not loaded'`);
        }
        if (!configuration.lookups.documentTypes) {
            throw new Error(`Configuration error documentTypes data was not loaded'`);
        }

        if (!configuration.lookups.issuingAuthorities) {
            throw new Error(`Configuration error issuingAuthorities data was not loaded'`);
        }
        if (!configuration.lookups.nationalities) {
            throw new Error(`Configuration error nationalities data was not loaded'`);
        }
        if (!configuration.lookups.nationalitiesOnly) {
            throw new Error(`Configuration error nationalitiesOnly data was not loaded'`);
        }
    }

    private isNullOrWhitespace(input: string) {
        if (typeof input === 'undefined' || input === null) return true;
        return input.replace(/\s/g, '').length < 1;
    }

    private getInputValues(jsonData): InputValues {
        const returnValue = {} as InputValues;


        // Default the document format and type
        const df: DocumentFormat = this.configuration.lookups.documentFormats.filter((df) => df.id === defaultDocFormatId)[0];

        returnValue.documentFormat = df;
        returnValue.documentType = this.getDefaultDocumentType();

        if (jsonData) {
            const parsedData = jsonData;
            if (parsedData.destination) { returnValue.destination = parsedData.destination; }
            if (parsedData.nationality) { returnValue.nationality = parsedData.nationality; }
            if (parsedData.issuingCountry) { returnValue.issuingCountry = parsedData.issuingCountry; }
            if (parsedData.originLocation) { returnValue.originLocation = parsedData.originLocation }
            if (parsedData.documentFormat) { returnValue.documentFormat = parsedData.documentFormat; }
            if (parsedData.documentType) { returnValue.documentType = parsedData.documentType; }
        }
        return returnValue;
    }

    private getDefaultDocumentType = (): DocumentType => this.configuration.lookups.documentTypes.filter((dt) => dt.name.toLowerCase() === defaultDocumentType)[0];

    public getPassenger(jsonData): Passenger {
        let passenger: Passenger;
        if (jsonData) {
            passenger = jsonData;

            if (passenger.segments.length === 0) {
                this.defaultSegments(passenger);
            }
            else {
                for (let i = 0; i < passenger.segments.length; i++) {
                    if (typeof passenger.segments[i].departureDate == "string") {
                        passenger.segments[i].departureDate = moment(passenger.segments[i].departureDate);
                    }
                }
            }

            if (passenger.documents.length === 0) {
                this.defaultDocument(passenger);
            } else {
                for (let i = 0; i < passenger.documents.length; i++) {
                    if (typeof passenger.documents[i].expiry == "string") {
                        passenger.documents[i].expiry = moment(passenger.documents[i].expiry);
                    }
                }
            }
        }
        else {
            passenger = new Passenger();
            this.defaultSegments(passenger);
            this.defaultDocument(passenger);
        }
        return passenger;
    }

    private defaultSegments(passenger: Passenger) {
        let departureDate: Date = null,
            returnDate: Date = null;
        if (this.configuration.segmentDatesDefault) {
            departureDate = new Date();
            const m = moment().add(this.configuration.segmentDatesDefault, 'days');
            returnDate = m.toDate();
        }

        // This is a bit hacky with the cast issue
        passenger.segments.push(new Segment());
        passenger.segments[0].departureDate = moment(departureDate);
        passenger.segments[0].segmentOrdinal = 1;
        passenger.segments.push(new Segment());
        passenger.segments[1].departureDate = moment(returnDate);
        passenger.segments[1].segmentOrdinal = 2;
    }

    private defaultDocument(passenger: Passenger) {
        const passportDT: DocumentType = this.getDefaultDocumentType();
        passenger.documents.push(new Document());
        passenger.documents[0].documentType = passportDT;
        passenger.documents[0].expiry = moment().add(1, 'years');
    }

}
