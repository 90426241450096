import { Lookups } from '../traveldoc';
import { AiConfig } from './aiconfig';
import { User } from './user';


export class JavaScriptValues {
  lookups: Lookups;
  aiConfig: AiConfig;
  user: User;
  baseUrl: string;
  infoPagesUrl: string;
  selectedCulture: string;
  instrumentationKey: string;
  tagManagerKey: string;
  segmentDatesDefault: number;
  termsUrl: string;
  privacyUrl: string;
  skin: string;
  readonly minDate: Date;
  readonly maxDate: Date;
  constructor() {
    this.lookups = new Lookups();
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 20, 0, 1);
    this.maxDate = new Date(currentYear + 20, 0, 1);
  }
}
