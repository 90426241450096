export class FeedbackData {
    // User data
    submittedBy: string;
    email: string;
    submittedAt: Date;
    company: string;
    userInputCompany: string;
    website: string;
    userComments: string;
    // rule data
    ruleName: string;
    applicationMessage: string;
    nationalies: string;
    tags: string;
    // Inputs Data
    destination: string;
    nationality: string;
    originLocation: string;
    issuingCountry: string;
    documentType: string;
    documentFormat: string;

    // Client Filters
    selectedTags: string;
    searchText: string;
}
