import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
//import { ConfigurationService } from '../services';
//import { config } from 'rxjs';

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {

  //constructor(private configurationService: ConfigurationService) {
    
  //}

  transform(date: Date, format: string): unknown {
    
    return moment(date).format(format);
  }

}
