import { Component, OnInit, Input } from '@angular/core';
import { ResultStatus } from '../../../../../models/enum/resultstatus';

@Component({
  selector: 'result-status-header',
  templateUrl: './result-status-header.component.html',
  styleUrls: ['./result-status-header.component.scss']
})
export class ResultStatusHeaderComponent implements OnInit {


  // TODO this needs to be refactored as it is almost exactly the same 
  // as the results statu with different styling and string content
  @Input() status: ResultStatus;
  // Exposed for clarity. this way we avoid magic numbers in the view
  resultsStatuses: typeof ResultStatus = ResultStatus; 


  constructor() { }

  ngOnInit() {
  }

  getClass(): string {
    let result = "";
    switch (this.status) {
      case ResultStatus.Green: { result = "green"; } break;
      case ResultStatus.Amber: { result = "amber"; } break;
      case ResultStatus.Red: { result = "red"; } break;

      // fall through for unknown and out of range
      case ResultStatus.Unknown:
      default: {
        result = "unknown";
        window.appInsights?.trackException({ error: new Error(`Result status '${this.status}' was not recognised`) });
      } break;
    }
    return result;
  }

}
