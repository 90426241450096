import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }

  transform(html : string, args: string): SafeHtml {
    args = this.escapeRegExp(args);
    
    if (!args) { return html; }
    const re = new RegExp(args, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
    
    const returnValue: string = html .replace(re, "<mark>$&</mark>");
    return this.sanitized.bypassSecurityTrustHtml(returnValue);
  }

  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
}
