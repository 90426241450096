import { Component, OnInit } from '@angular/core';
import { QuestionBase } from '../base/questionbase';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'question-document-format',
  templateUrl: './question-document-format.component.html',
  styleUrls: ['./question-document-format.component.scss']
})
export class QuestionDocumentFormatComponent extends QuestionBase implements OnInit {

  readonly documentTypeBiometric = '5';
  constructor() {
    super();
  }

    ngOnInit() {
        super.onSelected(new MatRadioChange(null, { key: this.documentTypeBiometric }));
  }

}
