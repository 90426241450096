import { Component, Input } from '@angular/core';
import { Passenger } from '../../../../../models/traveldoc';
import { DisplayGroup } from '../../../../../models/display/displaygroup';
import { DisplayCheckResult } from '../../../../../models/display/displaycheckresult';

@Component({
  selector: 'results-segments',
  templateUrl: './results-segments.component.html',
  styleUrls: ['./results-segments.component.scss']
})
export class ResultsSegmentsComponent {
  @Input() segmentsDisplayGroups: Array<DisplayGroup<DisplayCheckResult>>;
  @Input() passenger: Passenger;
}
