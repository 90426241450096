import { Input, Output, EventEmitter, ViewChild, ElementRef, Directive } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import {
  TravelDocAPIService,
  ConfigurationService
} from '../../../services';
import { FocusNextBase } from '../base/focusnextbase';



@Directive()
export abstract class AbstractAutocompleteComponent<T> extends FocusNextBase {

  filteredOptions: Observable<Array<T>>;
  isLoading = false;



  private _selectedItem: T | string;
  get selectedItem(): T | string {
    return this._selectedItem;
  }
  @Input() set selectedItem(value: T | string) {
    if (this._selectedItem !== value) {
      this._selectedItem = value;
      if (this.parentForm !== undefined) {
        this.parentForm.controls[this.controlName].setValue(value)
      }
    }
  }

  @Input() public items: Array<T>;

  @Input() public placeholder: string;

  @Input() public controlName: string;

  @Output() public changedEvent = new EventEmitter<T>();

  @Input() public parentForm: FormGroup;

  @ViewChild('autocompleteInput', { static: false }) autoCompleteInput: ElementRef;

  constructor(protected travelDocAPIService: TravelDocAPIService,
    protected configurationService: ConfigurationService) {
    super();
  }
  
  clear = () => this.parentForm.controls[this.controlName].setValue(undefined);

}
