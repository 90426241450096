import { Component, OnInit, Input } from '@angular/core';
import { Passenger, Location, CheckResults, Document } from '../../../../../models/traveldoc';
import { ObjectFormatterService } from '../../../../common/services';

@Component({
  selector: 'results-passenger-info',
  templateUrl: './results-passenger-info.component.html',
  styleUrls: ['./results-passenger-info.component.scss']
})
export class ResultsPassengerInfoComponent implements OnInit {

  @Input() passenger: Passenger;
  @Input() checkResults: CheckResults;
  documents: Array<Document>;

  constructor(private objectFormatterService: ObjectFormatterService) { }

  ngOnInit() {
    this.initDocuments();
  }

  formatLocation(location: Location): string {
    return this.objectFormatterService.getCountryShortDescription(location);
  }

  initDocuments() {
    if (!this.documents) {
      this.documents = new Array<Document>();
    }

    for (const document of this.passenger.documents) {
      this.documents.push(document);
    }

    for (const document of this.checkResults.documents) {
      const duplicates: Array<Document> = this.documents.filter(d =>
        d.documentType.name === document.documentType.name &&
        d.issuingCountry.countryICAO === document.issuingCountry.countryICAO &&
        d.issuingCountry.locationICAO === document.issuingCountry.locationICAO);

      const notInList: boolean = duplicates.length === 0;

      if (notInList) {
        this.documents.push(document);
      }
    }
  }
}
