import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../utils/error.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private errorService: ErrorService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(catchError((error: HttpErrorResponse) => {
        let errorMessage = '';

        if (error.error instanceof ErrorEvent) {
          // This is a client side error
          errorMessage = `A client-side error was encountered: ${error.error.message}`;
        } else {
          // This is a server side error
          if (error.status === 401) {
            // auto logout if 401 response returned from api
            //this.authenticationService.logout();
            window.appInsights?.trackException(error);
            location.reload();
          }
          else {
            errorMessage = `Server responded with error: ${error.statusText}, error code: ${error.status}, message: ${error.message}, url: ${error.url}`;
          }
        }
        window.appInsights?.trackException({ error: new Error(errorMessage) });
        this.errorService.addError(new Error("An error occured contacting the server"));
        return throwError(errorMessage);
      }));
  }
}
