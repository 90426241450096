import { Component } from '@angular/core';
import { ConfigurationService } from '../../../services';
import { ClientTranslateService } from '../../../services/i18n/client.translate.service';
import { languageLookup } from '../../../i18n/language.lookup';
import { LanguageOption } from '../../../../../models/traveldoc/languageoption';

@Component({
  selector: 'select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss'] 
})
export class SelectLanguageComponent {

  clientTranslateService: ClientTranslateService;
  cultures: Array<LanguageOption>;
  selectedLanguage: string;
  postAction: string;
  antiForgeryToken: string;

  constructor(private configurationService: ConfigurationService) {
    this.antiForgeryToken = this.configurationService.antiForgeryToken;
    this.cultures = this.filterLanguages();
    this.selectedLanguage = this.configurationService.configuration.selectedCulture;
    this.postAction = this.configurationService.configuration.baseUrl;
  }

    public OnChange(culture) {

        window.location.href = `${window.location.protocol}//${window.location.host}?culture=${culture}`;
        //window.location.search = `culture=${culture}`;
    }
    

  private filterLanguages(): Array<LanguageOption> {
    return languageLookup.filter(c => {
      const currentClient: string = window['td']['skin'];
      const clientfilter: Array<string> = c["clientCodes"];
      if (!clientfilter) {
        // we have no client filters specified
        return true;
      }

      if (!clientfilter.length) {
        return false;
      }

      return clientfilter.filter((showForClient: string) => {
        return showForClient === currentClient
      }).length > 0;

    });
  }

}
