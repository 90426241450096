import { Injectable } from '@angular/core';
import { Passenger, Segment, Document, DocumentType, LocationType } from '../../../../models/traveldoc';
import * as moment from 'moment';
import { ConfigurationService } from '../configuration/configuration.service';
import { InputValues } from '../../../../models/library';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QueryStringHelperService {
  private processedResultsQueryString = false;
  private baseURL: string;
  private documentTypes: Array<DocumentType>;
  private readonly contifurationService: ConfigurationService;
  constructor(private configurationService: ConfigurationService) {
    this.configurationService = configurationService;
    this.baseURL = configurationService.configuration.baseUrl;
    this.documentTypes = configurationService.configuration.lookups.documentTypes;
  }

  private emitQueryChangeSource = new Subject<string>();
  // Observable string streams
  changeEmitted$ = this.emitQueryChangeSource.asObservable();
  // Service message commands
  queryStringChanged(change: string) {
    this.emitQueryChangeSource.next((change) ? encodeURIComponent(change):undefined);
  }


  getQueryStringFromPassenger(passenger: Passenger): string {
    let returnString = '';
    try {
      returnString = this.getRawQueryStringFromPassenger(passenger);
      returnString = `${this.baseURL}?${returnString}`;
    } catch (e) {
      console.log(e);
    }
    return returnString;
  }

  getRawQueryStringFromPassenger(passenger: Passenger): string {
    let returnString = '';
    let first = true;

    // Inline Helper function for querystring
    const getPrefix = () => {
      const prefix = (first) ? '' : '&';
      first = false;
      return prefix;
    }

    try {
      for (const segment of passenger.segments) {
        const segString = this.formatSegment(segment);
        if (segString) {
          returnString += `${getPrefix()}${segString}`;
        }
      }
      for (const document of passenger.documents) {
        const docString = this.formatDocument(document);
        if (docString) {
          returnString += `${getPrefix()}${docString}`;
        }
      }
    } catch (e) {
      console.log(e);
    }

    return returnString;
  }


  private formatSegment(segment: Segment): string {
    let segmentString = '';

    if (!segment.origin || !segment.destination) {
      return segmentString;
    }

    if (segment.origin.type === LocationType.Airport && segment.destination.type === LocationType.Airport) {
      // Both airports is OK
      segmentString = `S=${segment.segmentOrdinal}|${segment.origin.locationICAO}|${segment.destination.locationICAO}|${this.formatDate(segment.departureDate)}`;
    }
    else {
      // Both countries is OK
        segmentString = `SC=${segment.segmentOrdinal}|${segment.origin.countryICAO}|${segment.destination.countryICAO}|${this.formatDate(segment.departureDate)}`;
    }
    return segmentString;
  }

  getQueryStringFromInputs(inputValues: InputValues): string {
    let returnValue = '';
    // Get a defaulted passenger so we use consistent dates for defaulting the segments
    const passenger: Passenger = this.buildPassengerFromInputValues(inputValues);
    returnValue = this.getRawQueryStringFromPassenger(passenger);
    return returnValue;
  }

  private buildPassengerFromInputValues(inputValues: InputValues): Passenger {
    const defaultPassenger: Passenger = this.configurationService.getPassenger(undefined);
    const outSegment: Segment = defaultPassenger.segments[0];
    const returnSegment: Segment = defaultPassenger.segments[1];
    const document: Document = defaultPassenger.documents[0];
    if (inputValues.destination) {
      outSegment.destination = inputValues.destination;
      returnSegment.origin = inputValues.destination;
    }
    if (inputValues.originLocation) {
      outSegment.origin = inputValues.originLocation;
      returnSegment.destination = inputValues.originLocation;
    }
    else {
      if (inputValues.nationality) {
        if (!outSegment.origin) {
          outSegment.origin = inputValues.nationality;
        }
        if (!returnSegment.destination) {
          returnSegment.destination = inputValues.nationality;
        }
      }
    }
    // Provide default segment date values if note are present
    if (!defaultPassenger.segments[0].departureDate) {
      let departureDate: Date = null,
        returnDate: Date = null;
        departureDate = new Date();
        const m = moment().add(this.configurationService.configuration.segmentDatesDefault || 14, 'days');
      returnDate = m.toDate();

      outSegment.departureDate = moment(departureDate);;
      returnSegment.departureDate = moment(returnDate);
    }


    if (inputValues.documentType) {
      document.documentType = inputValues.documentType;
    }
    if (inputValues.documentFormat) {
      document.documentFormat = inputValues.documentFormat;
    }
    if (inputValues.issuingCountry) {
      document.issuingCountry = inputValues.issuingCountry;
    }
    if (inputValues.nationality) {
      document.nationality = inputValues.nationality;
    }

    return defaultPassenger;
  }

  private formatDocument(document: Document): string {

    let formattedDocument = ''
    if (typeof document === "string" ||
      !document ||
      !document.documentType ||
      !document.issuingCountry ||
      !document.nationality ||
      !document.expiry) {
      return formattedDocument;
    }
    const dtid: number = document.documentType.documentTypeId;
    formattedDocument = `D=${dtid}|${document.issuingCountry.countryICAO}|${document.nationality.countryICAO}|${this.formatDate(document.expiry)}`;
    return formattedDocument;
  }

  private formatDate(date: moment.Moment): string {
    let formattedDate: string = ''
    if(moment.isDate(date))
    {
      date = moment(date);
    }

    if(moment.isMoment(date)){
      return date.format('YYYY-MM-DD')
    }
    return formattedDate ;
  }

  private getParameterByName(name: string, url: string) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  public get showResults(): boolean {
    if (this.processedResultsQueryString) {
      return false;
    }
    this.processedResultsQueryString = true;
    const queryValue = this.getParameterByName('results', window.location.href.toLowerCase());
    return (queryValue && queryValue.toLowerCase() === 'true');
  }

}
