import { Component, OnInit } from '@angular/core';
import { startWith, map } from 'rxjs/operators';
import { DocumentType } from '../../../../../models/traveldoc/documenttype';
import { AbstractAutocompleteComponent } from '../abstract.autocomplete/abstract.autocomplete.component';
import {  TravelDocAPIService, ConfigurationService } from '../../../services';


@Component({
  selector: 'documenttypeAutocomplete',
  templateUrl: './documenttype.autocomplete.component.html',
  styleUrls: ['./documenttype.autocomplete.component.scss']
})
export class DocumentTypeAutocompleteComponent extends AbstractAutocompleteComponent<DocumentType> implements OnInit {

  private _selected: DocumentType;
  get selectedItem() : DocumentType{
    return this._selected;
  }
  set selectedItem(value:DocumentType){
    this._selected = value;
  }

  constructor(travelDocAPIService: TravelDocAPIService,
    configurationService: ConfigurationService) {
    super(travelDocAPIService, configurationService);
  }

  ngOnInit() {
    if (this.selectedItem !== null) {
      this.parentForm.controls[this.controlName].setValue(this.selectedItem);
    }
    this.filteredOptions = this.parentForm.controls[this.controlName].valueChanges
      .pipe(
        startWith(''),
        map(input => typeof input === 'string' ? input : ''),
        map(value => this.filter(value))
      );
  }

  private filter(value: string): Array<DocumentType> {
    const filterValue = (value) ? value.toLowerCase() : '';
    return this.items.filter(option => (option && option.description) ? option.description.toLowerCase().includes(filterValue) : false);
  }

  format(value: DocumentType) {
    return (typeof value === 'string' || !value) ? value : value.description;
  }
}
