import { Injectable } from '@angular/core';
import { Observer, Observable, } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LibraryResult, Rule, RuleCategory, LibraryQuery, Tag, DisplayLinks, Location, Lookups } from '../../../../models/traveldoc';
import { FilterValues, ConstraintRule, PriorityRule, InputValues } from '../../../../models/library';
import { ConfigurationService } from '../configuration/configuration.service';
import { TravelDocAPIService } from '../http/travel-doc-api.service';
import { ObjectFormatterService } from './object-formatter.service';
import { QueryStringHelperService } from './query-string-helper.service';
import { ErrorService } from './error.service';
import { TranslateService } from '@ngx-translate/core';
import { ClientTranslateService } from '../i18n/client.translate.service';



@Injectable({
  providedIn: 'root'
})
export class LibraryDataService {


  lookups: Lookups;

  readonly clientTranslateService: ClientTranslateService;

  private lastDestination: Location;

  constraintCount$: Observable<number>;
  private constraintCountObserver: Observer<number>;

  private libraryResult$: Observable<LibraryResult>;
  private libraryResultObserver: Observer<LibraryResult>;

  private _libraryResult: LibraryResult;
  private set libraryResult(value: LibraryResult) {
    this._libraryResult = value;
    if (value === null) {
      this.priortiyRules = null;
      this.filteredRules = null;
      this.tags = null;
      this.displayLinks = null;
    }
    if (this.libraryResultObserver) {
      this.libraryResultObserver.next(this._libraryResult);
    }
  }
  private get libraryResult(): LibraryResult { return this._libraryResult; }

  private filteredRulesObserver: Observer<Array<ConstraintRule>>;
  private _filteredRules: Array<ConstraintRule>
  private set filteredRules(value: Array<ConstraintRule>) {
    this._filteredRules = value;
    if (this.filteredRulesObserver) {
      this.filteredRulesObserver.next(this._filteredRules);
    }
  }
  private get filteredRules(): Array<ConstraintRule> { return this._filteredRules; }

  private priortiyRulesObserver: Observer<Array<PriorityRule>>;
  private _priortiyRules: Array<PriorityRule> = new Array<PriorityRule>();
  private set priortiyRules(value: Array<PriorityRule>) {
    this._priortiyRules = value;
    if (this.priortiyRulesObserver) {
      this.priortiyRulesObserver.next(this._priortiyRules);
    }
  }

  private tagsObserver: Observer<Array<Tag>>;
  private _tags: Array<Tag> = new Array<Tag>();
  private set tags(value: Array<Tag>) {
    this._tags = value;
    if (this.tagsObserver) {
      this.tagsObserver.next(this._tags);
    }
  }
  private get tags(): Array<Tag> { return this._tags; }


  private displayLinksObserver: Observer<DisplayLinks>;
  private _displayLinks: DisplayLinks = new DisplayLinks();
  private set displayLinks(value: DisplayLinks) {
    this._displayLinks = value;
    if (this.displayLinksObserver) {
      this.displayLinksObserver.next(this._displayLinks);
    }
  }
  private get displayLinks(): DisplayLinks { return this._displayLinks; }

  constructor(private configurationService: ConfigurationService,
    private errorService: ErrorService,
    private travelDocApiService: TravelDocAPIService,
    private objectFomratterService: ObjectFormatterService,
    private querystringHelperService: QueryStringHelperService,
    private translateService: TranslateService) {
    this.lookups = configurationService.configuration.lookups;
    this.filteredRules = new Array<ConstraintRule>();

    // Listen for library results data
    this.libraryResult$ = new Observable<LibraryResult>((observer) => this.libraryResultObserver = observer);
    this.constraintCount$ = new Observable<number>((oberver) => this.constraintCountObserver = oberver);
    this.clientTranslateService = this.translateService as ClientTranslateService;

    

    // set up a pipe to convert the results to a useful format
    this.libraryResult$
      .subscribe(async (results: LibraryResult) => {
        try {
          if (results) {
            const rules: Array<Rule> = results.rules;
            const tags: Array<Tag> = results.tags;

            // build rules results
            if (rules && rules.length) {
              this.filteredRules = rules.filter(r => r.ruleCategory === RuleCategory.Constraint).map((r) => this.objectFomratterService.getConstraintRule(r, this.lookups.countryGroups));
              this.priortiyRules = this.objectFomratterService.getPriorityRules(rules);
            } else {
              this.filteredRules = [];
              this.priortiyRules = [];
            }
            // Update the rule count
            this.constraintCountObserver.next(this.filteredRules.length);

            // Process tags
            if (tags && tags.length) {
              this.tags = tags;
            }
            else {
              this.tags = [];
            }

            // Set up customs and health data
            this.displayLinks = this.objectFomratterService.getDisplayLinks(this.lastDestination, results);
          }
        } catch (e) {
          window.appInsights?.trackException(e);
          this.errorService.addError(new Error('An error occured processing the returned rules.'));
        }
      });
  }

  bindInputs($inputValues: Observable<InputValues>) {
    $inputValues.pipe(debounceTime(500)).subscribe(async (input) => {
      try {
        const lq: LibraryQuery = {
          destination: input.destination || null,
          origin: input.originLocation || null,
          documentFormat: input.documentFormat || null,
          documentType: input.documentType || null,
          issuingCountry: input.issuingCountry || null,
          nationality: input.nationality || null
        };
        // Send event to the header link
        this.querystringHelperService.queryStringChanged(this.querystringHelperService.getQueryStringFromInputs(input));

        if (lq.destination !== null) {
          this.lastDestination = lq.destination;

          this.libraryResult = await this.travelDocApiService.getResultsAsync(lq);


        }
        else {
          this.libraryResult = null;
        }
      } catch (e) {
        window?.appInsights.trackException(e);
        this.libraryResult = null;
        const error: Error = new Error(await this.clientTranslateService.get('services.libraryDataService.errorGettingRules').toPromise());
        this.errorService.addError(error)
      }
    });
  }

  initialiseData() {
    try {
      if (this.configurationService.libraryResult && this.configurationService.libraryResult.rules) {
        // If we have init data then set it
        this.querystringHelperService.queryStringChanged(this.querystringHelperService.getQueryStringFromInputs(this.configurationService.inputValues));
        this.lastDestination = this.configurationService.inputValues.destination;
        this.libraryResult = this.configurationService.libraryResult;
        this.priortiyRules = this.objectFomratterService.getPriorityRules(this.configurationService.libraryResult.rules);
      }
    } catch (e) {
      window.appInsights?.trackException(e);
      this.errorService.addError(new Error('failed to initialise the library rules.'));
    }
  }

  bindFilters($filterValue: Observable<FilterValues>) {
    $filterValue.subscribe(async (filter) => {


      // Search through the rules and see if we can match any text in the rule text
      const textCheck: (value: Rule) => boolean = (value: Rule) => {
        // Calculate the rule we are searching ( a bit inefficient but until we have a performance issue it's easier);
        const constraintRule: ConstraintRule = this.objectFomratterService.getConstraintRule(value, this.configurationService.configuration.lookups.countryGroups);
        const plainText = constraintRule.text.replace(/<[^>]*>?/gm, '').toLowerCase(); // strip any HTML tags
        return (
          (

            plainText.indexOf(filter.searchText.toLowerCase()) > -1 ||
            constraintRule.title.toLowerCase().indexOf(filter.searchText.toLowerCase()) > -1 ||
            constraintRule.appliesTo.toLowerCase().indexOf(filter.searchText.toLowerCase()) > -1 ||
            constraintRule.appliesToText.toLowerCase().indexOf(filter.searchText.toLowerCase()) > -1
          )
          &&
          value.ruleCategory === RuleCategory.Constraint); // only return constraints for filter
      };

      const tagCheck = (filterTags: Array<Tag>, ruleTags: Array<Tag>) => {
        if (filterTags.length === 0) {
          return true;
        }
        if (!ruleTags.length) {
          return false;
        }
        let result = false;

        for (let indexFilterTag = 0; indexFilterTag < filterTags.length; indexFilterTag++) {
          // Keep a running total of all the match filters. if we reach the same count as the filters
          // the tag contains them all so return true to select a matched rule
          let matchCount = 0;
          for (let indexRuleTag = 0; indexRuleTag < ruleTags.length; indexRuleTag++) {
            if (filterTags[indexFilterTag].name === ruleTags[indexRuleTag].name) {
              matchCount++;
              break;
            }
          }
          if (matchCount === filterTags.length) {
            result = true;
            break;
          }
        }
        return result;
      }

      // Combine the things that we want to check (could possibly build this a bit Linqey with currying)
      const check = (rule: Rule) => {
        return tagCheck(filter.filterTags, rule.tags) &&
          textCheck(rule);
      };
      try {
        // Update the filtered rules list
        if (this.libraryResult) {
          this.filteredRules = this.libraryResult.rules.filter(check).map((r) => this.objectFomratterService.getConstraintRule(r, this.lookups.countryGroups));
        }
      } catch (e) {
        window.appInsights?.trackException(e);
        this.errorService.addError(new Error('filtering rules.'));
      }
    });
  }

  attatchFilteredRulesWatch(filteredRulesObserver: Observer<Array<ConstraintRule>>) {
    this.filteredRulesObserver = filteredRulesObserver;
  }

  attatchPriorityRules(priortiyRulesObserver: Observer<Array<PriorityRule>>) {
    this.priortiyRulesObserver = priortiyRulesObserver;
  }

  attatchTags(tagsObserver: Observer<Array<Tag>>) {
    this.tagsObserver = tagsObserver;
  }

  attachDisplayLinks(displayLinksObserver: Observer<DisplayLinks>) {
    this.displayLinksObserver = displayLinksObserver;
  }

}
