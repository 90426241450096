import { Component, OnInit, Input } from '@angular/core';
import { CheckResults, Passenger } from '../../../../../models/traveldoc';

@Component({
    selector: 'results-additional-info',
    templateUrl: './results-additional-info.component.html',
    styleUrls: ['./results-additional-info.component.scss']
})
export class ResultsAdditionalInfoComponent implements OnInit {

    @Input() passenger: Passenger;
    @Input() checkResults: CheckResults;

    constructor() { }

    ngOnInit() {

    }

}
