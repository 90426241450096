import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { VendorsModule } from '../../../vendors/src/public_api';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NoSanitizePipe, HighlightPipe, MomentPipe } from '../../pipes';
import {
  QuestionAutocompleteComponent,
  LocationsAutocompleteComponent,
  DocumentTypeAutocompleteComponent,
  DocumentFormatAutocompleteComponent,
  ErrorComponent,
  SelectLanguageComponent,
  SiteFooterComponent,
  LoadingComponent,
  ResultsCountryPageComponent,
  SiteHeaderComponent
} from './';
import { SlideDownComponent } from './slide-down/slide-down.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ClientTranslateService } from '../../../common/services/i18n/client.translate.service';
import { ConfigurationService, TravelDocAPIService,  QueryStringHelperService, ObjectFormatterService, DataShimService, ErrorService, LoaderService, HttploaderinteceptorService, HttpInterceptorService } from '../../services';
// Register supported locales 
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeZhHans from '@angular/common/locales/zh-Hans'; // simplified
import localeZhHansExtra from '@angular/common/locales/extra/zh-Hans';
import localeZhHant from '@angular/common/locales/zh-Hant'; 
import localeZhHantExtra from '@angular/common/locales/extra/zh-Hant';
import { matCalendarFormats } from '../../i18n/calendar-formats.index';
import momentLocaleZhHant from '../../i18n/moment.zh-Hant';
import momentLocaleZhHans from '../../i18n/moment.zh-Hans';
import moment from 'moment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { languageLookup } from '../../i18n/language.lookup';
import { GlobalErrorService } from '../../services/logging/global-error.service';

moment.fn.toJSON = function () {return this.format('YYYY-MM-DD') + 'T00:00:00.000Z'; }

registerLocaleData(localeEn, 'en', localeEnExtra);
registerLocaleData(localeZhHans, 'zh-Hans', localeZhHansExtra);
registerLocaleData(localeZhHant, 'zh-Hant', localeZhHantExtra);
moment.defineLocale('zh-Hans', momentLocaleZhHans as object);
moment.defineLocale('zh-Hant', momentLocaleZhHant as object);

const culture = window['td']['selectedCulture'];
moment.locale(culture);


@NgModule({
  providers: [
    TravelDocAPIService,
    QueryStringHelperService,
    ObjectFormatterService,
    ConfigurationService,
    DataShimService,
    ErrorService,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: HttploaderinteceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: culture },
    { provide: MAT_DATE_LOCALE, useValue: culture, deps: [MAT_DATE_LOCALE] },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: matCalendarFormats[culture] },
    { provide: TranslateService, useClass: ClientTranslateService },
    { provide: ErrorHandler, useClass: GlobalErrorService },
  ],
  declarations: [
    LocationsAutocompleteComponent,
    QuestionAutocompleteComponent,
    DocumentTypeAutocompleteComponent,
    DocumentFormatAutocompleteComponent,
    ErrorComponent,
    SelectLanguageComponent,
    SiteFooterComponent,
    LoadingComponent,
    ResultsCountryPageComponent,
    SiteHeaderComponent,
    //************* PIPES
    NoSanitizePipe,
    HighlightPipe,
    SlideDownComponent,
    MomentPipe
    
  ],
  imports: [
    VendorsModule,
    TranslateModule.forRoot(),
  ],
  exports: [
    VendorsModule,
    TranslateModule,
    //************* COMPONENTS
    QuestionAutocompleteComponent,
    LocationsAutocompleteComponent,
    DocumentTypeAutocompleteComponent,
    DocumentFormatAutocompleteComponent,
    ErrorComponent,
    SelectLanguageComponent,
    SiteFooterComponent,
    LoadingComponent,
    ResultsCountryPageComponent,
    SiteHeaderComponent,
    SlideDownComponent,
    //************* PIPES
    NoSanitizePipe,
    HighlightPipe,
    MomentPipe
    
  ]
})
export class CommonModule {
  constructor(private translateService: TranslateService) {
    const clientTranslateService = this.translateService as ClientTranslateService;
    clientTranslateService.addClients(window['td']['skin']);
    clientTranslateService.addLangs(languageLookup.map(c => c.cultureCode));
    clientTranslateService.setDefaultLang('en');
    clientTranslateService.use(window['td']['selectedCulture']);
    clientTranslateService.setClient(window['td']['skin']);
  }
}
