import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss']
})
export class SiteFooterComponent implements OnInit {

  year: string;

  @Input() termsUrl: string;

  @Input() privacyUrl: string;



  constructor() {
    this.year = moment().format('YYYY');
  }

  ngOnInit() { }
}
