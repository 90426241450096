import { trigger, transition, animate, style, state, stagger, animateChild, query, group } from '@angular/animations';

const defaultTiming = '225ms cubic-bezier(0.4,0.0,0.2,1)';
const delayedDefaultTiming = '225ms 225ms cubic-bezier(0.4,0.0,0.2,1)';
const longerTiming = '500ms cubic-bezier(0.4,0.0,0.2,1)';

export const addRemove = trigger('addRemove', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(defaultTiming, style({ opacity: 1 })),
  ]),
  transition(':leave', [
    animate(defaultTiming, style({ opacity: 0 }))
  ])
]);
export const indicator = trigger('indicator', [
  state('true', style({ 'height': '*' })),
  state('false', style({ 'height': '0', 'visibility': 'hidden' })),
  transition('true => false', [
    animate(defaultTiming)
  ]),
  transition('false => true', [
    animate(defaultTiming)
  ])
]);
export const triangle = trigger('triangle', [
  state('HEALTH', style({ 'left': '16.66%' })),
  state('CUSTOMS', style({ 'left': '50%' })),
  state('OTHER', style({ 'left': '83.33%' })),
  transition('* <=> *', [
    animate(defaultTiming)
  ])
]);
export const openClose = trigger('openClose', [
  state('true', style({ 'height': '*' })),
  state('false', style({ 'height': '0' })),
  transition('false => true', [
    animate(defaultTiming)
  ]),
  transition('true => *', [
    animate(defaultTiming)
  ])
]);

export const indicatorRotate = trigger('indicatorRotate', [
  state('false', style({ transform: 'rotate(0deg)' })),
  state('true', style({ transform: 'rotate(-180deg)' })),
  transition("true => false", animate(defaultTiming)),
  transition("false => true", animate(defaultTiming))
]);
export const bodyExpansion = trigger('bodyExpansion', [
  state('false', style({ height: '0px', display: 'none' })),
  state('true', style({ height: '*', display: 'block' })),
  transition("true => false", animate(defaultTiming)),
  transition("false => true", animate(defaultTiming))
])
export const expanded = trigger("expanded", [
  state('true', style({ height: '*' })),
  state('false', style({ height: '0' })),
  transition("true => false", animate(defaultTiming)),
  transition("false => true", animate(defaultTiming))
]);

export const slideDown = trigger("slideDown", [
  state('void', style({ bottom: '-100%' })),
  state('true', style({ bottom: '0' })),
  state('false', style({ bottom: '-100%' })),
  transition("* => false", animate(defaultTiming)),
  transition("* => true", animate(defaultTiming))
]);

export const inputSummary = trigger("inputSummary", [
  state("true", style({ "height": "0", opacity: 0 })),
  state("false", style({ "height": "*", opacity: 1 })),
  transition("false => true", [animate(defaultTiming)]),
  transition("true => false", [animate(delayedDefaultTiming)])
]);
export const additonalInputs = trigger("additionalInputs", [
  state("true", style({ "height": "*", opacity: 1 })),
  state("false", style({ "height": "0", opacity: 0 })),
  transition("false => true", [
    animate(delayedDefaultTiming)
  ]),
  transition("true => false", [
    animate(defaultTiming)
  ])
]);
export const item = trigger('item', [
  // cubic-bezier for a tiny bouncing feel
  transition(':enter', [
    style({ transform: 'scale(0.5)', opacity: 0 }),
    animate(longerTiming,
      style({ transform: 'scale(1)', opacity: 1 }))
  ]),
  transition(':leave', [
    style({ transform: 'scale(1)', opacity: 1, }),
    animate(longerTiming,
      style({ transform: 'scale(0.5)', opacity: 0 }))
  ])
])

export const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
   group([
      query(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }),
        stagger('20ms', animate(longerTiming, style({ transform: 'scale(1)', opacity: 1 })))],
        { optional: true }
      ),
      query(':leave', [
        style({ opacity: 1, height: '*', transform: 'scale(1)' }),
        animate(longerTiming, style({ opacity: 0, height: 0, transform: 'scale(0.5)' }))],
        { optional: true }
      )])
  ])
]);
