import { Injectable } from '@angular/core';
import { DisplayGroup } from '../../../../models/display/displaygroup';
import {
    Segment,
    Location,
    CheckResult,
    DisplayLinks,
    RuleCategory,
    Rule,
    LibraryResult,
    Link,
    Operator,
    CountryGroup,
    LocationType,
    InterviewQuestion,
    Passenger
} from '../../../../models/traveldoc';
import { FilterValues, ConstraintRule, PriorityRule, InputValues } from '../../../../models/library';
import { FeedbackData } from '../../../../models/library/feedbackdata';
import { ConfigurationService } from '../configuration/configuration.service';
import { DisplayCheckResult } from '../../../../models/display/displaycheckresult';
import { PriorityItem } from '../../../../models/display/prioirtyitem';
import { MessageLevel } from '../../../../models/enum/messagelevel';
import { ResultStatus } from '../../../../models/enum/resultstatus';

@Injectable({
    providedIn: 'root'
})
export class ObjectFormatterService {

    constructor(private configurationService: ConfigurationService) {

    }

    generateQuestionDisplayGroups(segments: Array<Segment>, interviewQuestions: Array<InterviewQuestion>): Array<DisplayGroup<InterviewQuestion>> {
        const results: Array<DisplayGroup<InterviewQuestion>> = new Array<DisplayGroup<InterviewQuestion>>();
        const matcher = (segment: Segment, questions: Array<InterviewQuestion>): Array<InterviewQuestion> => {
            const matchedQuestions = questions.filter((value: InterviewQuestion) => value.segmentOrdinal === segment.segmentOrdinal);
            return matchedQuestions || [];
        };

        segments.forEach(s => {
            const dg: DisplayGroup<InterviewQuestion> = new DisplayGroup<InterviewQuestion>();
            dg.items = matcher(s, interviewQuestions);
            dg.from = this.getSegmentMessageTitle(s.origin);
            dg.to = this.getSegmentMessageTitle(s.destination);
            dg.date = s.departureDate;
            dg.segmentOrdinal = s.segmentOrdinal;
            dg.fromDescription = this.getSegmentMessageDescription(s.origin);
            dg.toDescription = this.getSegmentMessageDescription(s.destination);
            if (dg.items && dg.items.length) {
                results.push(dg);
            }
        })
        return results;
    }

    async generateResultDisplayGroups(passenger: Passenger, results: Array<CheckResult>): Promise<Array<DisplayGroup<DisplayCheckResult>>> {
        //const covidMessageCodes = ['9104', //Covid-19 Warning
        //  '9105', // PCR Test Required
        //  '9106', // Health Form Required
        //  '9107', // Passenger Locator Form Required
        //  '9108', // Authorisation Required
        //  '9109', // Insurance Required
        //  '9110', // Quarantine Required​
        //  '9111', // Vaccination required
        //  '9112', // Entry is not permitted due to Covid-19
        //  '9113', // Masks are required
        //  '9114'  //Vaccination definition (TBC)
        //];


        const displayGroupResults: Array<DisplayGroup<DisplayCheckResult>> = new Array<DisplayGroup<DisplayCheckResult>>();
        const segments = passenger.segments;
        //let previousSegment: Segment;
        for (const segment of segments) {
            for (const result of results) {
                const displayCheckResult = new DisplayCheckResult();


                // Set the initial message lists to be filtered as we progress
                displayCheckResult.level1Messages = result.level1Messages;
                displayCheckResult.level2Messages = result.level2Messages;
                displayCheckResult.iVisaLink = result.iVisaLink;

                if (result.segmentOrdinal === segment.segmentOrdinal) {
                    /*displayCheckResult.segmentOrdinal = segment.segmentOrdinal;*/
                    
                    const displayGroup: DisplayGroup<DisplayCheckResult> = new DisplayGroup<DisplayCheckResult>();
                    displayCheckResult.status = result.status;
                    displayGroup.from = this.getSegmentMessageTitle(segment.origin);
                    displayGroup.to = this.getSegmentMessageTitle(segment.destination);
                    displayGroup.date = segment.departureDate;
                    displayGroup.segmentOrdinal = segment.segmentOrdinal;
                    displayGroup.fromDescription = this.getSegmentMessageDescription(segment.origin);
                    displayGroup.toDescription = this.getSegmentMessageDescription(segment.destination);

                    displayGroup.items.push(displayCheckResult);
                    displayGroupResults.push(displayGroup);
                }
            }
        }


        return displayGroupResults;
    }


    getLinksObject(checkResult: CheckResult, segment: Segment): DisplayLinks {
        const links: DisplayLinks = new DisplayLinks();
        links.location = segment.destination;
        links.otherResources = this.getLinkHTML(checkResult.links);
        return links;
    }

    getCountryShortDescription(location: Location): string {
        return this.getSegmentMessageTitle(location);
    }

    getRules(rules: Array<Rule>): Array<Rule> {
        return rules.filter(r => r.ruleCategory === RuleCategory.Constraint);
    }

    getPriorityRules(rules: Array<Rule>): Array<PriorityRule> {
        const priorityRulesAsRules: Array<Rule> = rules.filter(r => r.ruleCategory != RuleCategory.Constraint),
            returnCollection: Array<PriorityRule> = new Array<PriorityRule>();

        for (const rule of priorityRulesAsRules) {
            const priorityRule: PriorityRule = new PriorityRule(rule.ruleCategory, rule.ruleText);
            returnCollection.push(priorityRule);
        }
        return returnCollection;
    }

    getConstraintRule(rule: Rule, countryGroups: Array<CountryGroup>): ConstraintRule {
        const cr: ConstraintRule = new ConstraintRule();
        cr.title = rule.ruleID;
        cr.text = rule.ruleText;
        cr.appliesTo = this.getAppliesToTitle(rule);
        cr.appliesToText = this.getAppliesToText(rule, countryGroups);
        cr.rule = rule;
        return cr;
    }

    getDisplayLinks(location: Location, libraryResult: LibraryResult): DisplayLinks {
        const returnValue: DisplayLinks = new DisplayLinks();
        returnValue.location = location;
        returnValue.otherResources = this.getLinkHTML(libraryResult.links);
        return returnValue;
    }


    getFeedbackData(userComments: string, rule: ConstraintRule, inputValues: InputValues, filterValues: FilterValues): FeedbackData {
        const inputValuesInstance = Object.create(InputValues.prototype);
        Object.assign(inputValuesInstance, inputValues);
        const inputData = inputValuesInstance.toFeedbackJSON();
        const ruleData = rule.toFeedbackJSON();
        const filterData = filterValues.toFeedbackJSON();
        const userData = this.configurationService.configuration.user.toFeedbackJSON();
        const data: FeedbackData = {
            submittedAt: new Date(),
            website: this.configurationService.configuration.baseUrl,
            userComments: userComments,
            ...ruleData,
            ...inputData,
            ...filterData,
            ...userData
        };
        return data;
    }

    private getLinkHTML(links: Array<Link>): string {
        let returnString = '';
        // 1.) Get the groups
        let categories: Array<string> = links.map((value: Link): string => {
            return value.categoryName;
        });

        // 2.) Get the links by group
        if (categories) {
            // Get unique categories
            categories = categories.filter((item, i, ar) => ar.indexOf(item) === i);
            categories.forEach((catName: string) => {
                const catLinks = links.filter((link: Link) => link.categoryName === catName)
                if (catLinks) {
                    catLinks.forEach((catLink: Link) => {
                        returnString += `<li><a href="${catLink.url}" target="_blank">${catLink.name}</a></li>`
                    })
                }
            });
        }

        return (returnString) ? `<ul>${returnString}</ul>` : null;
    }

    private getSegmentMessageTitle(location: Location): string {
        if (typeof location !== "object") {
            window.appInsights.trackEvent({ name: "getSegmentMessageTitle Non object location", properties: { "locationData": JSON.stringify(location), "locationType": typeof location } });
            return "Invalid Location Object";
        }
        return (location.type === LocationType.Country) ? `${location.countryDescription} - ${location.locationICAO}` : location.locationDescription;
    }

    private getSegmentMessageDescription(location: Location): string {
        return (location.type === LocationType.Country) ? '' : `${location.countryDescription}`;
    }

    private getAppliesToText(rule: Rule, countryGroups: Array<CountryGroup>): string {
        let returnValue = '';

        if (!rule.nationalitySmartMessages.length) {
            returnValue = 'All Nationalities';
        }
        else {
            for (const message of rule.nationalitySmartMessages) {
                if (countryGroups.map((cg) => (cg) ? cg.name : '').indexOf(message) > -1) {
                    returnValue += `<b><p>Country Group${message}</p></b>`;
                }
                else {
                    returnValue += `<p>${message}</p>`;
                }
            }
        }
        return returnValue;
    }

    private getAppliesToTitle(rule: Rule): string {
        return (rule.nationalityOperator === Operator.In) ? "Applies to" : "Applies to all except";
    }
}
