import { Component, OnInit } from '@angular/core';
import { startWith, map } from 'rxjs/operators';
import { DocumentFormat } from '../../../../../models/traveldoc';
import { AbstractAutocompleteComponent } from '../abstract.autocomplete/abstract.autocomplete.component';
import { TravelDocAPIService } from '../../../services/http/travel-doc-api.service';
import { ConfigurationService } from '../../../services/configuration/configuration.service';



@Component({
    selector: 'documentformatAutocomplete',
    templateUrl: './documentformat.autocomplete.component.html',
    styleUrls: ['./documentformat.autocomplete.component.scss']
})
export class DocumentFormatAutocompleteComponent extends AbstractAutocompleteComponent<DocumentFormat> implements OnInit {

    constructor(travelDocAPIService: TravelDocAPIService,
        configurationService: ConfigurationService) {
        super(travelDocAPIService, configurationService);
    }


  ngOnInit() {
        if (this.selectedItem != null) {
            this.parentForm.controls[this.controlName].setValue(this.selectedItem);
        }
        this.filteredOptions = this.parentForm.controls[this.controlName].valueChanges
            .pipe(
                startWith(''),
                map(input => typeof input === 'string' ? input : ''),
                map(value => this.filter(value))
            );
    }

    private filter(value: string): DocumentFormat[] {
        const filterValue = (value) ? value.toLowerCase() : '';
        return (this.items) ? this.items.filter(option => (option && option.description) ? option.description.toLowerCase().includes(filterValue) : false) : [];
    }

    format = (value: DocumentFormat) => (value) ? value.description : undefined;
}
