import { InterviewQuestion } from './interviewquestion';
import { Segment } from './segment';
import { Document } from './document';


export class Passenger {
  constructor() {
    this.documents = new Array<Document>();
    this.segments = new Array<Segment>();
  }
  travellingWithChildren = false;
  documents: Array<Document>
  questions: Array<InterviewQuestion>;
  segments: Array<Segment>;
}
