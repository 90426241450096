import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { map, switchMap, tap, finalize, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { TravelDocAPIService,  ConfigurationService } from '../../../services/';
import { AbstractAutocompleteComponent } from '../abstract.autocomplete/abstract.autocomplete.component';
import {
  LocationType,
  Location
} from '../../../../../models/traveldoc';
import { MatAutocomplete } from '@angular/material/autocomplete';

/**
 * @title Filter autocomplete
 */
@Component({
  selector: 'locationsAutocomplete',
  templateUrl: 'locations.autocomplete.component.html',
  styleUrls: ['locations.autocomplete.component.scss'],
})
export class LocationsAutocompleteComponent extends AbstractAutocompleteComponent<Location> implements OnInit {
  private readonly classname_airport: string = "airport";
  private readonly classname_location: string = "location";

  @Input() public useEnglish = false;

  @ViewChild(MatAutocomplete, { static: false }) autoComplete: MatAutocomplete;

  constructor(travelDocAPIService: TravelDocAPIService,
    configurationService: ConfigurationService) {
    super(travelDocAPIService, configurationService);
  }

  format(location: Location) {
    let locationString = '';
    if (typeof location !== 'string') {
      if (location && location.locationICAO) {
        if (location.type === LocationType.Airport) {
          locationString = `${location.locationICAO} - ${location.locationDescription} (${location.countryICAO})`;
        }
        else {
          locationString = `${location.locationICAO} - ${location.locationDescription}`;
        }
      }
    }
    return locationString;
  }

  getOptionClass(option: Location): string {
    let optionClass: string;
    switch (option.type) {
      case LocationType.Airport: { optionClass = this.classname_airport; } break;
      default: { optionClass = this.classname_location; } break;
    }
    return optionClass;
  }


  ngOnInit() {
    this.parentForm.controls[this.controlName].setValue(this.selectedItem);
    if (this.items) {
      // Work with a in memory list
      this.filteredOptions = this.parentForm.controls[this.controlName].valueChanges
        .pipe(map(value => this.filter(value)));

    } else {
      // get list from server
      this.filteredOptions = this.parentForm.controls[this.controlName].valueChanges
        .pipe(
          filter(text => text && text.length > 1),
          debounceTime(100),
          distinctUntilChanged(),
          tap(() => this.isLoading = true),
          switchMap(async value => await this.getResultsAsync(value)),
          finalize(() => this.isLoading = false)
        );
    }

  }




  private filter(value: string): Location[] {
    if (typeof value === 'string') {
      const filterValue = (value) ? value.toLowerCase() : '';
      return this.items.filter(option => this.testLocation(option, filterValue));
    }
  }

  private testLocation(location: Location, text: string): boolean {
    const locationString = this.format(location);
    return (location) ? locationString.toLowerCase().includes(text.toLowerCase()) : false;
  }

  private async getResultsAsync(value: string): Promise<Location[]> {
    if (typeof value === 'string') {
      try {
          return await this.travelDocAPIService.getLocationsAsync(value);    }
      catch (e) {
        window.appInsights?.trackException(e);
      }
    }
  }
}
