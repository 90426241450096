import { delay } from 'rxjs/operators';
import { of } from 'rxjs';

export class FocusNextBase {

    readonly tabDelay: number = 100;

    public constructor() { }

    focusNext(currentInput: HTMLElement) {
        const example = of(null);
        let nInput: HTMLElement = null,
            itterationInput: HTMLElement;
        example.pipe(delay(this.tabDelay))
            .subscribe(() => {
                const inputs = document.getElementsByTagName('input');
                for (let i = 0; i < inputs.length; i++) {
                    itterationInput = inputs[i];
                  if (itterationInput && currentInput && itterationInput.id === currentInput.id) {
                        if (inputs.length > i) {
                            nInput = inputs[i + 1];
                        }
                        break;
                    }
                }
                if (nInput) {
                    nInput.focus(); // Focus on the next element
                }
            })
    }

}
