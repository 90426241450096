import { Tag } from './tag';
import { RuleCategory } from '../enum/rulecategory';
import { Operator } from '../enum/operator';



export class Rule {
  ruleCategory: RuleCategory = RuleCategory.Constraint;
  ruleID: string = null;
  ruleText: string = null;
  priority: number = 0;
  ordinal: number = 0;
  nationalityOperator: Operator = Operator.In;
  nationalityCodes: Array<string> = new Array<string>();
  nationalitySmartMessages: Array<string> = new Array<string>();
  issuingCountryOperator: Operator = Operator.In;
  issuingCountryCodes: Array<string> = new Array<string>();
  tags: Array<Tag> = new Array<Tag>();
  fieldMessages: Array<string> = new Array<string>();
  documentTypes: Array<string> = new Array<string>();
}
