import { Injectable } from '@angular/core';
import { ConfigurationService } from '../configuration/configuration.service';
import { Location } from '../../../../models/traveldoc';

class CodeMapping {
  constructor(codes: Array<string>, codeMapping: string) {
    this.codes = codes;
    this.codeMapping = codeMapping;
  }
  codes: Array<string>;
  codeMapping: string;
}


@Injectable({
  providedIn: 'root'
})
export class DataShimService {

  private readonly issuedBymappings: Array<CodeMapping> = [
    new CodeMapping(["GBD", "GBO", "GBS", "GBP", "GBN"], "GBR")
  ];

  constructor(private configurationService: ConfigurationService) {

  }

  mitigateNationality(location: Location): Location {
    if (location === undefined || location.countryDescription === undefined) {
      return undefined;
    }
    const result = this.configurationService.configuration.lookups.nationalities.filter((item: Location) => location.countryICAO === item.countryICAO);
    return (result.length) ? result[0] : undefined;
  }

  mitigateIssuingCountry(location: Location): Location {
    let returnLocation: Location;
    let locationsQueryResults: Array<Location>;
    if (location === undefined || location.countryDescription === undefined) {
      return;
    }
    const codesList: Array<string>
      = this.issuedBymappings.map((item: CodeMapping) => {
        if (item.codes.indexOf(location.countryICAO) > -1) {
          return item.codeMapping;
        }
      }).filter((value: string) => (value));



    if (codesList.length !== 1) {
      locationsQueryResults = this.configurationService.configuration.lookups.issuingAuthorities.filter((item: Location) => location.countryICAO === item.countryICAO);
    }
    else {
      locationsQueryResults = this.configurationService.configuration.lookups.issuingAuthorities.filter((item: Location) => codesList[0] === item.countryICAO);
    }

    if (locationsQueryResults && locationsQueryResults.length) {
      returnLocation = locationsQueryResults[0];
    }

    return returnLocation;
  }

}
