import { Injectable } from '@angular/core';
import { Passenger, CheckResults, DocumentType } from '../../../../models/traveldoc';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  passenger: Passenger;
  results: CheckResults;
}
