import { Claim } from './claim';

export class User {
  authenticated: boolean;
  claims: Array<Claim>; 

  public isApproved(): boolean {
    const CLAIM_NAME = 'approved';
    const CLAIM_VALUE = 'true';
    let result = false;
    if (this.getClaimValue(CLAIM_NAME) === CLAIM_VALUE) {
      result = true;
    }
    return result;
  }

  public isSupportOrAbove(): boolean {
    const CLAIM_NAME = 'traveldoc_role';
    const CLAIM_VALUES = ['admin', 'support'];
    let result = false;
    if (CLAIM_VALUES.indexOf(this.getClaimValue(CLAIM_NAME)) > -1) {
      result = true;
    }
    return result;

  }

  public getClaimValue(claimName: string) {
    let value: string = null;
    if (this.claims) {
      for (const claim of this.claims) {
        if (claim.key && claim.key.toLowerCase() === claimName) {
          value = claim.value;
        }
      }
    }
    return value;
  }

  toFeedbackJSON() {
    const claimEmail = "name";
    const claimCompany = "company";
    const claimUserInputCompany = "user_input_company";
    const claimFullName = "full_name";
    return {
      submittedBy: this.getClaimValue(claimFullName),
      email: this.getClaimValue(claimEmail),
      company: this.getClaimValue(claimCompany),
      userInputCompany: this.getClaimValue(claimUserInputCompany),
    }
  }
}
