import { Moment } from 'moment';
import { Location } from '.';

export class Segment {
  segmentGuid: string;
  departureDate: Moment;
  destination: Location;
  origin: Location;
  segmentOrdinal: number;
  transit: boolean;
  transitHours: number;
}
