import { Tag } from '../traveldoc/tag';
import { Observer } from 'rxjs';

export class FilterValues {

    private _observer: Observer<FilterValues>;

    constructor(observer: Observer<FilterValues> = null) {
        this._observer = observer;
        this._searchText = '';
        this._filterTags = [];
    }
    private _searchText: string;
    get searchText(): string { return this._searchText; }
    set searchText(value: string) {
        this._searchText = value;
        this.trigger();
    }

    //searchText: string;
    private _filterTags: Array<Tag>;
    get filterTags(): Array<Tag> { return this._filterTags; }
    set filterTags(value: Array<Tag>) {
        this._filterTags = value;
        this.trigger();
    }

    private trigger() {
        if (this._observer) this._observer.next(this);
    }

    toFeedbackJSON() {
        return {
            selectedTags: (this._filterTags) ? this._filterTags.map(t=>t.name).toString() : 'No filter tags selected',
            searchText: this.searchText
        };
    }

}
