import { Component, OnInit, Input } from '@angular/core';
import { CheckResults, DisplayLinks, CheckResult, Passenger } from '../../../../../models/traveldoc';
import { ObjectFormatterService } from '../../../../common/services';

@Component({
    selector: 'results-country-pages',
    templateUrl: './results-country-pages.component.html',
    styleUrls: ['./results-country-pages.component.scss']
})
export class ResultsCountryPagesComponent implements OnInit {

    @Input() checkResults: CheckResults;
    @Input() passenger: Passenger;

    linksInfo: Array<DisplayLinks>;


    constructor(private objectFormatterService: ObjectFormatterService) { }

    ngOnInit() {
        this.linksInfo = new Array<DisplayLinks>();
        this.checkResults.resultsList.forEach((item: CheckResult, index: number, array: Array<CheckResult>) => {
            let linkInfo: DisplayLinks = this.objectFormatterService.getLinksObject(item, this.passenger.segments[index]);
            this.linksInfo.push(linkInfo);
        });
    }

}
