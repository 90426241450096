import { Component, OnInit, OnDestroy } from '@angular/core';
import { ErrorService } from '../../../services';
import { item } from '../../../animations/animations';
import { Subscription } from 'rxjs';
import { error } from '@angular/compiler/src/util';

@Component({
  animations: [item],
  selector: 'error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {

  error: Error;

  subscriber: Subscription;

  constructor(private errorService: ErrorService) {

  }
  ngOnInit(): void {
    this.subscriber = this.errorService.$error.subscribe((error) => {
      this.error = error;
    });
  }


  ngOnDestroy() {
    this.subscriber.unsubscribe();
    this.subscriber = null;
  }
  remove() {
    this.error = null;
  }

}
