import { Component, Input } from '@angular/core';
import {
  MatDialog
} from '@angular/material/dialog'

import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';
import { DataService, QueryStringHelperService } from '../../../../common/services';

@Component({
  selector: 'passenger-view-feedback',
  templateUrl: './passenger-view-feedback.component.html',
  styleUrls: ['./passenger-view-feedback.component.scss']
})
export class PassengerViewFeedbackComponent {

  @Input()  segmentOrdinal: number;
  constructor(public dialog: MatDialog,
    private querystringHelperService: QueryStringHelperService,
    private dataService: DataService) { }
  
  openDialog(): void {
    let portString: string = (window.location.port) ? `:${window.location.port}`:'';
    const dialogRef = this.dialog.open(FeedbackDialogComponent, {
      minWidth: '350px',
      minHeight: '100px',
      data: {
        name: '',
        email: '',
        description: '',
        segmentOrdinal: this.segmentOrdinal,
        queryLink: `${window.location.protocol}//${window.location.hostname}${portString}/?${this.querystringHelperService.getRawQueryStringFromPassenger(this.dataService.passenger)}&results=true`
      }
    });
  }
}
