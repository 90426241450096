import { default as en } from './traveldoc.en.json';
import { default as enKLM } from './traveldoc.en.klm.json';
import { default as enAirfrance } from './traveldoc.en.airfrance.json';
import { default as enTransavia } from './traveldoc.en.transavia.json';
import { default as enCeair } from './traveldoc.en.ceair.json';
import { default as enAirSenegal } from './traveldoc.en.airsenegal.json';
import { default as enLuxair } from './traveldoc.en.luxair.json';

import { default as nl } from './traveldoc.nl.json';
import { default as nlKLM } from './traveldoc.nl.klm.json';
import { default as nlAirfrance } from './traveldoc.nl.airfrance.json';
import { default as nlLuxair } from './traveldoc.nl.luxair.json';

import { default as fr } from './traveldoc.fr.json';
import { default as frKLM } from './traveldoc.fr.klm.json';
import { default as frAirfrance } from './traveldoc.fr.airfrance.json';
import { default as frTransavia } from './traveldoc.fr.transavia.json';
import { default as frAirSenegal } from './traveldoc.fr.airsenegal.json';
import { default as frLuxair } from './traveldoc.fr.luxair.json';

import { default as zhHans } from './traveldoc.zh-Hans.json';
import { default as zhHansCeair } from './traveldoc.zh-Hans.ceair.json';
import { default as zhHansKLM } from './traveldoc.zh-Hans.klm.json';
import { default as zhHansAirfrance } from './traveldoc.zh-Hans.airfrance.json';
import { default as zhHansLuxair} from './traveldoc.zh-Hans.luxair.json';

import { default as zhHant } from './traveldoc.zh-Hant.json';
import { default as zhHantCeair } from './traveldoc.zh-Hant.ceair.json';
import { default as zhHantKLM } from './traveldoc.zh-Hant.klm.json';
import { default as zhHantAirfrance } from './traveldoc.zh-Hant.airfrance.json';
import { default as zhHantLuxair} from './traveldoc.zh-Hant.luxair.json';

import { default as es } from './traveldoc.es.json';
import { default as esKLM } from './traveldoc.es.klm.json';
import { default as esAirfrance } from './traveldoc.es.airfrance.json';
import { default as esLuxair } from './traveldoc.es.luxair.json';

import { default as ptPT } from './traveldoc.pt-PT.json';
import { default as ptPTKLM } from './traveldoc.pt-PT.klm.json';
import { default as ptPTAirfrance } from './traveldoc.pt-PT.airfrance.json';
import { default as ptPTLuxair } from './traveldoc.pt-PT.luxair.json';

import { default as ptBR } from './traveldoc.pt-BR.json';
import { default as ptBRKLM } from './traveldoc.pt-BR.klm.json';
import { default as ptBRAirfrance } from './traveldoc.pt-BR.airfrance.json';
import { default as ptBRLuxair} from './traveldoc.pt-BR.luxair.json';

import { default as de } from './traveldoc.de.json';
import { default as deLuxair } from './traveldoc.de.luxair.json';

import { default as it } from './traveldoc.it.json';
import { default as itLuxair } from './traveldoc.it.luxair.json';



export const availableLanguages = {
    "en": en,
    "en.klm": enKLM,
    "en.airfrance": enAirfrance,
    "en.transavia": enTransavia,
    "en.ceair": enCeair,
    "en.airsenegal": enAirSenegal,
    "en.luxair": enLuxair,
    "nl": nl,
    "nl.klm": nlKLM,
    "nl.airfrance": nlAirfrance,
    "nl.luxair": nlLuxair,
    "fr": fr,
    "fr.klm": frKLM,
    "fr.airfrance": frAirfrance,
    "fr.transavia": frTransavia,
    "fr.airsenegal": frAirSenegal,
    "fr.luxaur": frLuxair,
    "zh-Hans": zhHans,
    "zh-Hans.ceair": zhHansCeair,
    "zh-Hans.klm": zhHansKLM,
    "zh-Hans.airfrance": zhHansAirfrance,
    "zh-Hans.luxair": zhHansLuxair,
    "zh-Hant": zhHant,
    "zh-Hant.ceair": zhHantCeair,
    "zh-Hant.klm": zhHantKLM,
    "zh-Hant.airfrance": zhHantAirfrance,
    "zh-Hant.luxair": zhHantLuxair,
    "es": es,
    "es.klm": esKLM,
    "es.airfrance": esAirfrance,
    "es.luxair": esLuxair,
    "pt-PT": ptPT,
    "pt-PT.klm": ptPTKLM,  
    "pt-PT.airfrance": ptPTAirfrance,
    "pt-PT.luxair": ptPTLuxair,
    "pt-BR": ptBR,
    "pt-BR.klm": ptBRKLM,
    "pt-BR.airfrance": ptBRAirfrance,
    "pt-BR.luxair": ptBRLuxair,
    "de": de,
    "de.luxair": deLuxair,
    "it": it,
    "it.luxair": itLuxair
};
