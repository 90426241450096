import { Component, ViewChildren, QueryList } from '@angular/core';
import { InterviewQuestion, QuestionType, Segment } from '../../../../../models/traveldoc';
import { TravelDocAPIService, ObjectFormatterService, DataService, ErrorService } from '../../../../common/services';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DisplayGroup } from '../../../../../models/display/displaygroup';
import { QuestionBase } from '../base/questionbase';
import { ClientTranslateService } from '../../../../common/services/i18n/client.translate.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent {

  @ViewChildren(QuestionBase) questionComponents: QueryList<QuestionBase>;

  questions: Array<InterviewQuestion>;

  qustionDisplayGroups: Array<DisplayGroup<InterviewQuestion>>;

  questionTypes = QuestionType;
  form: FormGroup;
  segments: Array<Segment>;

  readonly clientTranslateService: ClientTranslateService;

  constructor(private travelDocAPIService: TravelDocAPIService,
    private router: Router,
    private dataService: DataService,
    private objectFormatterService: ObjectFormatterService,
    private translateService: TranslateService,
    private errorService: ErrorService) {
    this.questions = this.dataService.passenger.questions;
    this.clientTranslateService = this.translateService as ClientTranslateService;
    this.init();
  }

  getControlname = (question: InterviewQuestion, index: number) => {
    return `question_${index - 1}`;
  };

  showControl(questionType: number, type: QuestionType) {
    return (questionType as QuestionType === type);
  }

  async continue() {
    try {
      this.dataService.passenger.questions = this.questions;
      this.dataService.results = await this.travelDocAPIService.checkPassengerAsync(this.dataService.passenger);
      
      if (this.dataService.results) {
        this.router.navigate(['results']);
      }
    } catch (e) {
      window.appInsights?.trackException(e);
      this.errorService.addError(new Error(await this.clientTranslateService.get('passengerview.questions.error.gettingResults').toPromise()));
    }
  }

  back() {
    // Clear out the questions
    this.dataService.passenger.questions.splice(0, this.dataService.passenger.questions.length);
    this.router.navigate(['../']);
  }


  private init() {
    try {
      const group = {};
      this.questions.forEach((question: InterviewQuestion, key) => {
        // reset any answer
        question.answer = undefined;
        const ctrl: FormControl = new FormControl(question.answer, Validators.required);
        const controlName: string = this.getControlname(question, key);
        ctrl.setValue(question.answer);
        group[controlName] = ctrl;
      });
      this.form = new FormGroup(group);

      // make sure we don't get an object reference
      if (this.dataService &&
        this.dataService.passenger &&
        this.dataService.passenger.segments) {
        this.segments = this.dataService.passenger.segments;
      }
      else {
        this.segments = new Array<Segment>();
      }

      this.qustionDisplayGroups = this.objectFormatterService.generateQuestionDisplayGroups(this.segments, this.questions);
    } catch (e) {
      window.appInsights?.trackException(e);
      this.clientTranslateService.get('passengerview.questions.error.initailisingPage')
        .pipe(take(1))
        .subscribe((message: string) => {
          this.errorService.addError(new Error(message));
        });
    }
  
  }
}




