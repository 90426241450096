import { NgModule } from '@angular/core';
import { Routes, RouterModule, Event, Router, NavigationEnd } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { QuestionsComponent } from './questions/questions.component';
import { ResultsComponent } from './results/results.component';
import { filter } from 'rxjs/operators';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'questions',
    component: QuestionsComponent
  },
  {
    path: 'results',
    component: ResultsComponent
  },
  {   // This route will redirect if we have any MVC strangeness
    // to simplify the routing
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  },
];

@NgModule({
  //imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.router.events
      .pipe(filter((routerEvent: Event) => routerEvent instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (window.td.tagManagerKey) {
          gtag('config', window.td.tagManagerKey, { "page_path": event.urlAfterRedirects });
        }
        window.appInsights?.trackPageView({ name: event.urlAfterRedirects });
      });
  }
}
