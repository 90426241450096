import { FormGroup } from '@angular/forms';
import { Input, Directive } from '@angular/core';
import { FocusNextBase } from './focusnextbase';

@Directive()
export class ItemBase extends FocusNextBase {

    form: FormGroup;
    @Input() controlPrefix: number;
    // wrapper function prevents setting the selected value to the input text
    protected checkWrapper = ((value: any, callback: Function): void => { if (typeof value != 'string') callback(); });

    public getControlName = (name: string): string => `${this.controlPrefix}_${name}`;

}
