import { QuestionType } from '../enum/questiontype';
import { InterviewAnswer } from '.';

export class InterviewQuestion {
  segmentOrdinal: number;
  questionType: QuestionType;
  questionReferences: string;
  questionText: string;
  answerList: Array<InterviewAnswer>;
  answer: string;
}
