import { Component, Input } from '@angular/core';
import { DisplayCheckResult } from '../../../../../models/display/displaycheckresult';
import { MatDialog } from '@angular/material/dialog';
import { TravelDocAPIService } from '../../../../common/services/http/travel-doc-api.service';
@Component({
    selector: 'results-segment',
    templateUrl: './results-segment.component.html',
    styleUrls: ['./results-segment.component.scss']

})
export class ResultsSegmentComponent {
    constructor(public dialog: MatDialog,
        private traveldocAPIService: TravelDocAPIService) {

    }
    @Input() checkResult: DisplayCheckResult;
    @Input() segmentOrdinal: number;

    public async iVisaOnClick(url: string) {
        await this.traveldocAPIService.logiVisaClick(url)
    }


}
