import { default as en } from './calendar-formats.en.json';
import { default as zhHans } from './calendar-formats.zh-Hans.json';
import { default as zhHant } from './calendar-formats.zh-Hant.json';

export const matCalendarFormats = {
    'en': en,
    'fr': en,
    'nl': en,
    'es': en,
    'de': en,
    'it': en,
    'pt-PT': en,
    'pt-BR': en,
    'zh-Hans': zhHans,
    'zh-Hant': zhHant
}
