import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import {
    Passenger,
    InterviewQuestion,
    LibraryResult,
    Location,
    CheckResults,    Lookups
} from '../../../../models/traveldoc';

import {
    LibraryQuery
} from '../../../../models/params';

import { ConfigurationService } from '../configuration/configuration.service';
import { FeedbackData } from '../../../../models/library/feedbackdata';
import { config } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FeedbackMessage } from '../../../../models/traveldoc/feedbackMessage';

@Injectable({
    providedIn: 'root'
})
export class TravelDocAPIService {
    private readonly baseURL: string;
    private readonly infoPagesURL: string;
    private readonly getLocationsUrl: string;
    private lookups: Lookups;

    private readonly httpHeaders: HttpHeaders;

    constructor(private http: HttpClient,
        private configurationService: ConfigurationService) {


        this.baseURL = this.configurationService.configuration.baseUrl.replace(/\/$/, '');
        this.lookups = this.configurationService.configuration.lookups;
        this.infoPagesURL = this.configurationService.configuration.infoPagesUrl;
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.configurationService.antiForgeryToken
        });

        this.getLocationsUrl = (configurationService.isLibrary) ? `//${this.baseURL}/library/rules/getlocations` : './getlocations';
    }

    //#region InfoPages Calls

    public async getHealth(icaoCode: string): Promise<string> {
        return this.getHTML(`${this.infoPagesURL}/Health/${icaoCode}/Plain?language=${this.configurationService.configuration.selectedCulture}`);
    }

    public async getCustoms(icaoCode: string): Promise<string> {
        return this.getHTML(`${this.infoPagesURL}/Customs/${icaoCode}/Plain?language=${this.configurationService.configuration.selectedCulture}`);
    }


    //#endregion


    //#region API Calls

    public async getInterviewAsync(passenger: Passenger): Promise<Array<InterviewQuestion>> {
        return await this.http
            .post<Array<InterviewQuestion>>('./getinterview' as const, passenger, { responseType: 'json' as const, headers: this.httpHeaders })
            .toPromise<Array<InterviewQuestion>>();
    }

    public async submitFeedbackAsync(feedbackData: FeedbackData): Promise<void> {
        await this.http
            .post(`./library/rules/submitfeedback` as const, feedbackData, { responseType: 'json' as const, headers: this.httpHeaders })
            .toPromise();
    }

    public async checkPassengerAsync(passenger: Passenger): Promise<CheckResults> {
        return await this.http
            .post<CheckResults>(`./checkpassenger` as const, passenger, { responseType: 'json' as const, headers: this.httpHeaders })
            .toPromise<CheckResults>();
    }

    public async getResultsAsync(libraryQuery: LibraryQuery): Promise<LibraryResult> {
        return await this.http
            .post<LibraryResult>(`./library/rules/getresults` as const, libraryQuery, { responseType: 'json' as const, headers: this.httpHeaders })
            .toPromise<LibraryResult>();
    }

    public async getLocationsAsync(searchText: string): Promise<Array<Location>> {
        return await this.http
            .post<Array<Location>>(this.getLocationsUrl, JSON.stringify(searchText), { responseType: 'json' as const, headers: this.httpHeaders })
            .toPromise<Array<Location>>();
    }

    public async submitPassengerViewFeedback(feedback: FeedbackMessage) {
        return await this.http
            .post('./submitFeedbackRequest' as const, feedback, { headers: this.httpHeaders })
            .toPromise();
    }

    public async logiVisaClick(iVisaUrl: string) {
        return await this.http
            .post('./LogIVisa' as const, JSON.stringify({ url: encodeURI(iVisaUrl) }),  { responseType: 'json' as const, headers: this.httpHeaders })
            .toPromise();
    }


    //#endregion

    //#region private methods

    private async getHTML(url: string): Promise<string> {
        return this.http.get(url, { responseType: 'text' })
            .toPromise();
    }

    //#endregion

}
