import { Component, Input } from '@angular/core';
import { indicatorRotate, bodyExpansion } from '../../../animations/animations';

@Component({
  selector: 'slide-down',
  templateUrl: './slide-down.component.html',
  styleUrls: ['./slide-down.component.scss'],
  animations: [
    indicatorRotate,
    bodyExpansion
  ]
})
export class SlideDownComponent {

  @Input() isOpen = false;

  toggle() {
    this.isOpen = !this.isOpen;
  }

  @Input() title: string;

  @Input() content: string;
}
