import { Component, OnInit, Input } from '@angular/core';
import { ResultStatus } from '../../../../../models/enum/resultstatus';

@Component({
  selector: 'result-status',
  templateUrl: './result-status.component.html',
  styleUrls: ['./result-status.component.scss']
})
export class ResultStatusComponent implements OnInit {


  @Input() status: ResultStatus;
  // Exposed for clarity. this way we avoid magic numbers in the view
  resultsStatuses: typeof ResultStatus = ResultStatus; 

  constructor() { }

  ngOnInit() {
  }


  getClass(): string {
    let result = "";
    switch (this.status) {
      case ResultStatus.Green: { result = "green"; } break;
      case ResultStatus.DomesticSegmentCustomStatus :
      case ResultStatus.Amber: { result = "amber"; } break;
      case ResultStatus.Red: { result = "red"; } break;
      // fall through for unknown and out of range
      case ResultStatus.Unknown:
      default: {
        result = "unknown";
        window.appInsights?.trackException({ exception: new Error(`Result status '${this.status}' was not recognised`) });
      } break;
    }
    return result;
  }

}
