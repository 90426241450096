import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { CheckResults, Passenger, Segment } from '../../../../../models/traveldoc';
import { ObjectFormatterService, DataService, ErrorService } from '../../../../common/services';
import { DisplayGroup } from '../../../../../models/display/displaygroup';
import { TranslateService } from '@ngx-translate/core';
import { ClientTranslateService } from '../../../../common/services/i18n/client.translate.service';
import { take } from 'rxjs/operators';
import { DisplayCheckResult } from '../../../../../models/display/displaycheckresult';
import moment, { Moment } from 'moment';
import { ResultStatus } from '../../../../../models/enum/resultstatus';
/*import type{ ApplicationInsights} from '@microsoft/applicationinsights-web'*/
@Component({
  selector: 'results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
  checkResults: CheckResults;

  passenger: Passenger;

  resultsValidityDate: Moment;

  segmentsDisplayGroups: Array<DisplayGroup<DisplayCheckResult>>;

  clientTranslateService: ClientTranslateService;

  showBack() {
    return this.dataService.passenger &&
      this.dataService.passenger.questions &&
      this.dataService.passenger.questions.length > 0;
  }

  constructor(private router: Router,
    private dataService: DataService,
    private objectFormatterService: ObjectFormatterService,
    private errorService: ErrorService,
    private translageService: TranslateService,
    private location: Location) {
    this.checkResults = dataService.results;
    this.passenger = dataService.passenger;
    this.clientTranslateService = this.translageService as ClientTranslateService;
  }

  ngOnInit() {
    try {

      this.objectFormatterService.generateResultDisplayGroups(
        this.passenger,
        this.checkResults.resultsList)
        .then((result) => {
          this.segmentsDisplayGroups = result;
        });
      //Post process result
      if (this.passenger.segments.every((s) => s.origin.countryICAO === s.destination.countryICAO)) {
        if (this.checkResults.overallStatus !== ResultStatus.Red) {
          this.checkResults.overallStatus = ResultStatus.Amber;
        }
      }

      this.resultsValidityDate = moment.utc(this.checkResults.resultsValidityDate);

    } catch (e) {
      window.appInsights?.trackException(e);
      this.clientTranslateService.get('passengerview.results.error.initailisingPage')
        .pipe(take(1))
        .subscribe((message: string) => {
          this.errorService.addError(new Error(message));
        });
    }
  }

  adjustResultForDomestic() {
    let previousSegment: Segment;
    this.passenger.segments.every((segment) => {
      if (previousSegment && previousSegment.destination.countryICAO === segment.origin.countryICAO) {
        if (this.checkResults.overallStatus === ResultStatus.Green) {
          this.checkResults.overallStatus = ResultStatus.Amber;
          return false;
        }
      }

      previousSegment = segment;

    })
  }

  

  home() {
    // Clear out the questions
    this.dataService.passenger.questions.splice(0, this.dataService.passenger.questions.length);
    this.router.navigate(['../']);
  }

  back() {
    this.location.back();
  }

}
