import {
  Location,
  DocumentType,
  DocumentFormat,
  CountryGroup
} from './index';


export class Lookups {
  nationalities: Array<Location>
  nationalitiesOnly: Array<Location>;
  issuingAuthorities: Array<Location>;
  documentTypes: Array<DocumentType>;
  documentFormats: Array<DocumentFormat>;
  countryGroups: Array<CountryGroup>;
}
