/****************************************************************************************************
 * MODULE IMPORTS
 ****************************************************************************************************/
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '../../../common/src/public-api';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

/****************************************************************************************************
 * CUSTOM APLICATION COMPONENT IMPORTS
 ****************************************************************************************************/
import { AppComponent } from './app.component';

import {
    HomeComponent,
    QuestionsComponent,
    QuestionAirportComponent,
    QuestionDocumentFormatComponent,
    QuestionDocumentTypeComponent,
    ResultsAdditionalInfoComponent,
    ResultsCountryPagesComponent,
    ResultsPassengerInfoComponent,
    ResultsSegmentComponent,
    ResultsSegmentsComponent,
    ResultsComponent,
    YourTripComponent,
    ReturnTripComponent,
    OneWayComponent,
    SegmentComponent,
    SegmentsComponent,
    DocumentComponent,
    DocumentsComponent,
    SegmentHeaderComponent,
    ResultStatusComponent,
    PassengerViewFeedbackComponent,
    FeedbackDialogComponent

} from './index';



/****************************************************************************************************
 * COMMON SERVICE IMPORTS
 ****************************************************************************************************/
import { ConfigurationService, DataService } from '../../../common/services';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { ResultStatusHeaderComponent } from './result-status-header/result-status-header.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        QuestionsComponent,
        QuestionAirportComponent,
        QuestionDocumentFormatComponent,
        QuestionDocumentTypeComponent,
        ResultsAdditionalInfoComponent,
        ResultsCountryPagesComponent,
        ResultsPassengerInfoComponent,
        ResultsSegmentComponent,
        ResultsSegmentsComponent,
        ResultsComponent,
        YourTripComponent,
        ReturnTripComponent,
        OneWayComponent,
        SegmentComponent,
        SegmentsComponent,
        DocumentComponent,
        DocumentsComponent,
        SegmentHeaderComponent,
        ResultStatusComponent,
        ResultStatusHeaderComponent,
        PassengerViewFeedbackComponent,
        FeedbackDialogComponent

    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        HttpClientModule
    ],
    providers: [
        DataService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, configurationService: ConfigurationService) {
        configurationService.isLibrary = false;
        iconRegistry.addSvgIconSet(
            domSanitizer.bypassSecurityTrustResourceUrl('./passengerview/assets/mdi.svg')
        );
    }
}
