import { DocumentFormat } from './documentformat';
import { Location } from './location';
import { DocumentType } from './documenttype';
import { Moment } from 'moment';


export class Document {
    documentGuid: string;
    documentFormat: DocumentFormat;
    documentType: DocumentType;
    //name: string;
    expiry: Moment;
    issuingCountry: Location;
    nationality: Location;
}
