import { RuleMessage } from '../traveldoc/rulemessage';
import { PriorityItem } from './prioirtyitem';
import { ResultStatus } from '../enum/resultstatus';

export class DisplayCheckResult {
  status: ResultStatus;
    iVisaLink: string;
    /*segmentOrdinal:number*/
    priorityLevel1Messages: Array<PriorityItem>;
    level1Messages: Array<RuleMessage>;
    level2Messages: Array<RuleMessage>;
  constructor() {
    this.priorityLevel1Messages = new Array<PriorityItem>();
    this.level1Messages = new Array<RuleMessage>();
    this.level2Messages = new Array<RuleMessage>();
  }
}
