import { Injectable } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {


  private errors: Array<Error>;

  private tick: boolean;

  private readonly seconds = interval(5000);

  $error: Observable<Error>;

  constructor() {
    this.tick = true;
    this.errors = new Array<Error>();
    this.$error = this.seconds
      // only tick when we have messages to remove
      .pipe(filter(() => this.tick),
        map(() => {
          let error: Error = null;
          if (this.errors.length > 0) {
            error = this.errors[0];
            this.errors.shift();
          }
          else {
            this.tick = false;
          }
          return error;
        }));
  }

  addError(error: Error) {
    //console.log('an error occured',error);
    this.errors.push(error);
    this.tick = true;

  }

  removeError() {
    this.errors.pop();
  }


}
