import { Component, Input, ViewChildren, QueryList, EventEmitter, Output } from '@angular/core';
import { Segment } from '../../../../../models/traveldoc';
import { FormGroup } from '@angular/forms';
import { SegmentComponent } from '../segment/segment.component';
import { addRemove } from '../../../../common/animations/animations';
import { ConfigurationService } from '../../../../common/services';
import * as moment from 'moment';

@Component({
  animations: [
    addRemove
  ],
  selector: 'segments',
  templateUrl: './segments.component.html',
  styleUrls: ['./segments.component.scss']
})
export class SegmentsComponent {

  @ViewChildren(SegmentComponent) segmentComponents: QueryList<SegmentsComponent>;
  @Input() segments: Array<Segment>;
  @Output() originChanged: EventEmitter<Location> = new EventEmitter<Location>();;
  form: FormGroup;

  constructor(private configuraionService: ConfigurationService) {
    this.form = new FormGroup({});
  }

  ngAfterViewInit() {
    this.segmentComponents.changes.subscribe(() => {
      this.BindChildForms();
    })
    this.BindChildForms();
  }

  addSegment() {
    const newSegment = new Segment(),
      lastSegment = this.segments[this.segments.length - 1];
    newSegment.segmentOrdinal = this.segments.length + 1;
    newSegment.origin = lastSegment.destination;
    newSegment.departureDate = this.getNextDate(lastSegment);
    this.segments.push(newSegment);
  }

  removeSegment(segment: Segment) {
    for (let i: number = this.segments.length - 1; i > -1; i--) {
      this.form.removeControl(this.getName(i));
    }
    this.segments.splice(this.segments.indexOf(segment), 1);
    this.reIndexSegments();
  }

  onOriginChange(location: Location) {
    if (this.originChanged) this.originChanged.emit(location);
  }

  private BindChildForms() {
    this.segmentComponents.forEach((instance, index) => {
      instance.form.setParent(this.form);
      this.form.addControl(this.getName(index), instance.form);
    });
  }

  getName(index: number): string {
    return `segment${index}`;
  }

  private getNextDate = (previousSegment: Segment): moment.Moment => {

    if (!previousSegment || !previousSegment.departureDate) return;
    let returnDate: moment.Moment = moment(previousSegment.departureDate);

    if (previousSegment.departureDate && previousSegment.departureDate.isValid) {
      returnDate.add(this.configuraionService.configuration.segmentDatesDefault | 0, 'days');
    }

    return returnDate;
  }

  private reIndexSegments() {
    for (let i = 0; i < this.segments.length; i++) {
      this.segments[i].segmentOrdinal = i + 1;
    }
  }
}
