import {
  Location,
  DocumentType,
  DocumentFormat
} from '../traveldoc/index'
import { Observer } from 'rxjs';

export class InputValues {

  private subscriber: Observer<InputValues>

  //constructor();
  constructor(subscriber?: Observer<InputValues>, inputValues?) {
    Object.assign(this, inputValues);
    this.subscriber = subscriber;
  }

  destination: Location;
  nationality: Location;
  originLocation: Location;
  issuingCountry: Location;
  documentType: DocumentType;
  documentFormat: DocumentFormat;

  triggerUpdate() {
    if (this.subscriber) {
      this.subscriber.next(this);
    }
  }

  toJSON() {
    return JSON.stringify({
      destination: this.destination,
      nationality: this.nationality,
      issuingCountry: this.issuingCountry,
      originLocation: this.originLocation,
      documentType: this.documentType,
      documentFormat: this.documentFormat
    });
  }

  toFeedbackJSON() {
    // Returns a flattented string object used to provide 
    // selected input values for the feedback email
    return {
      destination: (this.destination) ? this.destination.countryICAO : null,
      nationality: (this.nationality) ? this.nationality.countryICAO : null,
      issuingCountry: (this.issuingCountry) ? this.issuingCountry.countryICAO : null,
      originLocation: (this.originLocation) ? this.originLocation.countryICAO : null,
      documentType: (this.documentType) ? this.documentType.name : null,
      documentFormat: (this.documentFormat) ? this.documentFormat.description : null
    };
  }

}
