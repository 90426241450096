import { TranslateService, TranslateLoader, TranslateCompiler, TranslateParser, MissingTranslationHandler } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { availableLanguages } from '../../i18n/traveldoc.index';
import { mergeObjects } from '../../utility/merge';


@Injectable({
  providedIn: 'root'
})
export class ClientTranslateService extends TranslateService implements TranslateService {

  private availableClients: Array<string> = [];
  allowedLanguages: Array<string> = [];
  currentClient: string;
  constructor(public currentLoader: TranslateLoader,
    public compiler: TranslateCompiler,
    public parser: TranslateParser,
    public missingTranslationHandler: MissingTranslationHandler) {
    super(null, currentLoader, compiler, parser, missingTranslationHandler, true, true, true, 'en');

    this.buildTranlations();
  }

  get languageCode(): string {
    return this.GetLanguageCode(this.currentLang || this.defaultLang);
  }

  get cultureCode(): string {
    return this.GetCultureCode(this.currentLang || this.defaultLang);
  }

  get clients(): Array<string> {
    return this.availableClients;
  }


  private formatRootLanguage(language: string) {
    return this.GetLanguageCode(language);
  }

  private formatClientLanguage(language: string, client: string) {
    const languageCode = this.GetLanguageCode(language);
    return (client) ? `${languageCode}.${client}` : undefined;
  }

  private formatSpecificCulture(language: string) {
    const languageCode = this.GetLanguageCode(language);
    const cultureCode = this.GetCultureCode(language);
    return (cultureCode) ? `${languageCode}-${cultureCode}` : undefined;
  }

  private formatClientSpecificCulture(language: string, client: string) {
    const languageCode = this.GetLanguageCode(language);
    const cultureCode = this.GetCultureCode(language);
    return (cultureCode) ? `${languageCode}-${cultureCode}.${client}` : undefined;
  }

  buildTranlations() {
    const exceptions = ['zh-Hans', 'zh-Hant'];
    const language = this.currentLang || this.defaultLang;
    const client = this.currentClient;
    const itemsToMerge = [];
    const rootLanguageKey = this.formatRootLanguage(this.defaultLang);
    const specificLanguageKey = this.formatRootLanguage(this.currentLang);
    const clientLanguageKey = this.formatClientLanguage(language, client);
    const specificCultureKey = this.formatSpecificCulture(language);
    const clientSpecificCultureKey = this.formatClientSpecificCulture(language, client);

    let specificLanguage, specificLanguageAndClient, specificCulture, specificCultureAndClient;


    const defaultLanguage = availableLanguages[rootLanguageKey];
    if (!defaultLanguage) throwError('There must be a default language file present in the application');
    itemsToMerge.push(defaultLanguage);

    if (exceptions.indexOf(this.currentLang) === -1) {
      if (specificLanguageKey) {
        specificLanguage = availableLanguages[specificLanguageKey];
        if (specificLanguage !== undefined) {
          itemsToMerge.push(specificLanguage);
        }
      }

      if (clientLanguageKey) {
        specificLanguageAndClient = availableLanguages[clientLanguageKey];
        if (specificLanguageAndClient !== undefined) {
          itemsToMerge.push(specificLanguageAndClient);
        }
      }

      if (specificCultureKey) {
         specificCulture = availableLanguages[specificCultureKey];
        if (specificCulture !== undefined) {
          itemsToMerge.push(specificCulture);
        }
      }

      if (clientSpecificCultureKey) {
        specificCultureAndClient = availableLanguages[clientSpecificCultureKey];
        if (specificCultureAndClient !== undefined) {
          itemsToMerge.push(specificCultureAndClient);
        }
      }
    }
    else {
      specificLanguage = availableLanguages[this.currentLang];
      if (specificLanguage) {
        itemsToMerge.push(specificLanguage);
      }

      // In the case of chinese languages the process is slightly different helpfully being language-script[-culture]
      specificLanguageAndClient = availableLanguages[`${this.currentLang}.${this.currentClient}`];
      if (specificLanguageAndClient) {
        itemsToMerge.push(specificLanguageAndClient);
      }
    }

    const translation = mergeObjects({}, ...itemsToMerge);
    this.setTranslation(this.currentLang || this.defaultLang, translation, true);
  }


  use(lang: string): Observable<object> {
    this.currentLang = lang;
    this.buildTranlations();
    return null;
  }

  setClient(client: string): Observable<object> {
    if (client !== undefined && this.clients.indexOf(client) === -1) {
      throwError('Invalid client selection');
    }
    this.currentClient = client;
    return this.use(this.currentLang);
  }

  addClients(...args: Array<string>) {
    this.availableClients.push(...args)
  }
  addLangs(langs: Array<string>) {
    this.allowedLanguages = langs;
    super.addLangs(langs);
  }

  private GetCultureCode(language: string): string {
    if (language === undefined || language.length === 2) {
      return undefined
    }
    else {
      return language.split('-', 2)[1];
    }
  }

  private GetLanguageCode(language: string): string {
    if (language === undefined || language.length === 2) {
      return language
    }
    else {
      return language.split('-', 1)[0];
    }
  }

  public getParsedResult(translations: any, key: any, interpolateParams?: Object): any {
    return super.getParsedResult(translations, key, interpolateParams);
  }
  public get(key: string | Array<string>, interpolateParams?: Object): Observable<string | any> {
    return super.get(key, interpolateParams);
  }
}
