export enum PageType {
    PassengerView,
    LibraryView,
    Integration,
    Widget,
    Android,
    Contact
}



