import { Component } from '@angular/core';
import { QuestionBase } from '../base/questionbase';

@Component({
    selector: 'question-airport',
    templateUrl: './question-airport.component.html',
    styleUrls: ['./question-airport.component.scss']
})
export class QuestionAirportComponent extends QuestionBase {

    constructor() {
        super();
    }

}
