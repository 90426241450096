export const languageLookup = [
    { "cultureCode": "en", "name": "English" },
    { "cultureCode": "zh-Hans", "name": "简体中文", "clientCodes": ["icts", "afkl", "airasia", "airfrance", "alaskaairlines", "cebu", "evaair", "kenyaairways", "klm", "skyteam", "starlux", "china-airlines", "ceair"] },
    { "cultureCode": "zh-Hant", "name": "繁體中文", "clientCodes": ["icts", "afkl", "airasia", "airfrance", "alaskaairlines", "cebu", "evaair", "kenyaairways", "klm", "skyteam", "starlux", "china-airlines", "ceair"] },
    { "cultureCode": "nl", "name": "Nederlands", "clientCodes": ["icts", "afkl", "airfrance", "klm", "luxair"] },
    { "cultureCode": "fr", "name": "Français", "clientCodes": ["icts", "afkl", "airfrance", "klm", "transavia", "airsenegal", "luxair"] },
    { "cultureCode": "es", "name": "español", "clientCodes": ["icts", "aireuropa"] },
    { "cultureCode": "pt-PT", "name": "português (Portugal)", "clientCodes": ["icts", "aireuropa", "luxair"] },
    { "cultureCode": "pt-BR", "name": "português (Brasil)", "clientCodes": ["icts", "aireuropa", "luxair"] },
    { "cultureCode": "it", "name": "italiano", "clientCodes": ["icts", "luxair"] },
    { "cultureCode": "de", "name": "Deutsch", "clientCodes": ["icts", "luxair"] }
];


