import { Injectable, ErrorHandler } from '@angular/core';
import { ErrorService } from '../utils/error.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorService extends ErrorHandler {

  constructor(private errorService: ErrorService) {
    super();
  }

  handleError(error: Error) {
    this.errorService.addError(error)
  }
}
