import { Component, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { Segment, Location, Document } from '../../../../../models/traveldoc';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { FormGroup } from '@angular/forms';
import { ReturnTripComponent } from '../return-trip/return-trip.component';
import { SegmentsComponent } from '../segments/segments.component';
import { SegmentFormType } from '../../../../../models/enum/segmentformtype';
import { DataShimService, DataService } from '../../../../common/services';
import { OneWayComponent } from '../one-way/one-way.component';

@Component({
  selector: 'your-trip',
  templateUrl: './your-trip.component.html',
  styleUrls: ['./your-trip.component.scss']
})
export class YourTripComponent { //implements OnInit  {

  formType: SegmentFormType = SegmentFormType.Return;
  initialSelectedIndex: number;

  @Input() form: FormGroup;
  @Input() segments: Array<Segment>;
  @Output() originChangedEvent = new EventEmitter<Location>();

  @ViewChild(ReturnTripComponent, { static: false }) returnTripComponent: ReturnTripComponent;
  @ViewChild(OneWayComponent, { static: false }) oneWayComponent: OneWayComponent;
  @ViewChild(SegmentsComponent, { static: false }) multiSegmentComponent: SegmentsComponent;
  @ViewChild("yourTripTabs", { static: false }) yourTripTabs: MatTabGroup;

  constructor(private dataService: DataService,
    private dataShimService: DataShimService) {
    this.form = new FormGroup({});
  }
  calculateTab(): number {
    switch (this.segments.length) {
      case 1: {
        return 1;
      }
      case 2: {
        const firstSegment = this.segments[0];
        const secondSegment = this.segments[1];
        const locations = [];
        if (firstSegment.origin) {
          locations.push(firstSegment.origin.locationICAO);
        }
        if (firstSegment.destination) {
          locations.push(firstSegment.destination.locationICAO);
        }
        if (secondSegment.origin) {
          locations.push(secondSegment.origin.locationICAO);
        }
        if (secondSegment.destination) {
          locations.push(secondSegment.destination.locationICAO);
        }
        const distinctLocations = locations.filter((loc, i) => locations.indexOf(loc) === i);

        if (distinctLocations.length > 2) {
          return 2;
        }
        else {
          return 0;
        }
      }
      default: {
        return 2;
      }
    }
  }


  ngOnInit() {
    this.initialSelectedIndex = this.calculateTab();
  }
  ngAfterViewInit() {
    switch (this.calculateTab()) {
      case 1: {
        this.yourTripTabs.selectedIndex = 1;
        if (this.oneWayComponent) {
          this.form.addControl(SegmentFormType.OneWay.toString(), this.oneWayComponent.form);
        }
      } break;
      case 0: {
        this.yourTripTabs.selectedIndex = 0;
        if (this.returnTripComponent) {
          this.form.addControl(SegmentFormType.Return.toString(), this.returnTripComponent.form);
        }
      } break;
      default: {
        this.yourTripTabs.selectedIndex = 2;
        if (this.multiSegmentComponent) {
          this.form.addControl(SegmentFormType.MultiSegment.toString(), this.multiSegmentComponent.form);
        }
      } break;
    }
  }

  originChanged(location: Location) {
    if (this.dataService.passenger.documents && this.dataService.passenger.documents.length > 0) {
      const document: Document = this.dataService.passenger.documents[0];

      if (document !== undefined && document.issuingCountry === undefined) {
        document.issuingCountry = this.dataShimService.mitigateIssuingCountry(location)
      }
      if (document !== undefined && document.nationality === undefined) {
        //document.nationality = document.issuingCountry;
        document.nationality = this.dataShimService.mitigateNationality(location);
      }

    }
    else {
      throw new Error('Error in your-trip component originChanged : expected passenger to have at least one document but none were available');
    }
  }

  onChangeTab(event: MatTabChangeEvent) {
    this.form.removeControl(SegmentFormType.Return.toString());
    this.form.removeControl(SegmentFormType.OneWay.toString());
    this.form.removeControl(SegmentFormType.MultiSegment.toString());
    switch (event.index) {
      case 0: {
        this.formType === SegmentFormType.Return;
        this.form.addControl(SegmentFormType.Return.toString(), this.returnTripComponent.form);
        this.segments.splice(2, this.segments.length - 2);
        if (this.segments.length === 1) {
          const newSegment: Segment = new Segment(),
            firstSegment: Segment = this.segments[0];
          if (!firstSegment) throw new Error('Unexpected error in your-trip control: Initial segment is missing');

          newSegment.origin = firstSegment.destination;
          newSegment.destination = firstSegment.origin;
          newSegment.departureDate = firstSegment.departureDate
          this.segments.push(newSegment);
        }
        this.reIndexSegments();
      } break;
      case 1: {
        this.formType === SegmentFormType.OneWay;
        this.form.addControl(SegmentFormType.OneWay.toString(), this.oneWayComponent.form);
        this.segments.splice(1, this.segments.length - 1);
        this.reIndexSegments();
      } break;
      case 2: {
        this.formType === SegmentFormType.MultiSegment;
        this.form.addControl(SegmentFormType.MultiSegment.toString(), this.multiSegmentComponent.form);
      } break;
    }
  }

  transitInfoClick() {
    event.stopPropagation();
  }

  private reIndexSegments() {
    for (let i = 0; i < this.segments.length; i++) {
      this.segments[i].segmentOrdinal = i + 1;
    }
  }

}

