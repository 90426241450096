import { Component, Input } from '@angular/core';
import { DisplayLinks } from '../../../../../models/traveldoc';
import { TravelDocAPIService, ErrorService } from '../../../services';
import { indicator, openClose, triangle } from '../../../animations/animations';

const PNL_CUSTOMS = 'CUSTOMS';
const PNL_HEALTH = 'HEALTH';
const PNL_OTHER = 'OTHER';

@Component({
  animations: [
    indicator,
    triangle,
    openClose,
  ],
  selector: 'results-country-page',
  templateUrl: './results-country-page.component.html',
  styleUrls: ['./results-country-page.component.scss']
})
export class ResultsCountryPageComponent {

  private expanded = false;

  currentPanel = '';
  customsHtml = '';
  healthHtml = '';
  otherHtml = '';
  countryName = '';

  @Input() set displayLinks(value: DisplayLinks) {
    this.currentPanel = '';
    this.countryName = '';
    this.expanded = false;
    try {
      if (value && value.location) {
        this.countryName = value.location.countryDescription;
        this.otherHtml = value.otherResources;
        this.travelDocAPIService.getCustoms(value.location.countryICAO).then((value) => this.customsHtml = value);
        this.travelDocAPIService.getHealth(value.location.countryICAO).then((value) => this.healthHtml = value);
      }
      else {
        this.otherHtml = '';
        this.customsHtml = '';
        this.healthHtml = '';
      }
    } catch (e) {
      this.errorService.addError(new Error("An unexpected error was encountered retrieving customs or health data"));
    }
  }

  constructor(private travelDocAPIService: TravelDocAPIService,
    private errorService: ErrorService) { }

  changePanel(contentType: string) {
    if (this.expanded && this.currentPanel === contentType) {
      this.expanded = false;
      this.currentPanel = '';
      return;
    }
    else if (!this.expanded) {
      this.expanded = true;
    }
    this.currentPanel = contentType;
  }
}
