import { Input, Directive } from '@angular/core';
import { InterviewQuestion, InterviewAnswer } from '../../../../../models/traveldoc';
import { FormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
@Directive({})
export class QuestionBase {
  @Input() public question: InterviewQuestion;
  @Input() public parentForm: FormGroup;
  @Input() public controlName: string;

  onSelected(args: MatRadioChange) {
    this.question.answer = args.value.key;
  }

  onSelectedAutoComplete(answer: InterviewAnswer) {
    this.question.answer = answer.key;
  }
}
