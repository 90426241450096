import { Location } from '../traveldoc/location';
import { DocumentType } from '../traveldoc/documenttype';
import { DocumentFormat } from '../traveldoc/documentformat';

export class LibraryQuery {

    destination: Location;
    origin: Location;
    nationality: Location;
    issuingCountry: Location;
    documentType: DocumentType;
    documentFormat: DocumentFormat;
}
