import { Moment } from 'moment';

export class DisplayGroup<T> {
  from: string;
  to: string;
  date: Moment;
  fromDescription: string;
  toDescription: string;
  items: Array<T>;
  segmentOrdinal: number;
  constructor() {
    this.from = '';
    this.to = '';
    this.date = null;
    this.items = new Array<T>();
    this.segmentOrdinal= 0;
  }
}
