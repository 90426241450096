import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ConfigurationService } from '~common/configuration/configuration.service';
import { Lookups, Segment, Location } from '~models/traveldoc';
import { ItemBase } from '~common/components/base/itembase';
import { CustomValidators } from '~common/validators/custom-validators';
import { Moment } from 'moment'

@Component({
  selector: 'segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss']
})
export class SegmentComponent extends ItemBase implements OnInit {
  form: FormGroup;
  lookups: Lookups;

  get controls() { return this.form.controls; }

  @Output() segmentRemoved = new EventEmitter<Segment>();
  @Output() originChanged: EventEmitter<Location> = new EventEmitter<Location>();
  @Input() segment: Segment;
  @Input() index = 0;

  @ViewChild("segmentDate", { static: false }) datepickerInput: ElementRef;

  public readonly minDate: Date;
  public readonly maxDate: Date;

  readonly ctrl_from: string = "from";
  readonly ctrl_to: string = "to";
  readonly ctrl_date: string = "date";

  constructor(configurationService: ConfigurationService) {
    super();
    this.lookups = configurationService.configuration.lookups;
    this.form = new FormGroup({});
    this.minDate = configurationService.configuration.minDate;
    this.maxDate = configurationService.configuration.maxDate;
  }

  ngOnInit() {
    const fromControlName: string = this.getControlName('from');
    const toControlName: string = this.getControlName('to');
    const dateControlName: string = this.getControlName('date');

    this.form.addControl(fromControlName, new FormControl('', CustomValidators.objectSelectedValidator));
    this.form.addControl(toControlName, new FormControl('', CustomValidators.objectSelectedValidator));
    this.form.addControl(dateControlName, new FormControl('', null));

    this.controls[fromControlName].setValue(this.segment.origin);
    this.controls[toControlName].setValue(this.segment.destination);
    this.controls[dateControlName].setValue(this.segment.departureDate);

    this.controls[fromControlName].valueChanges.subscribe((value: Location) => {
      this.checkWrapper(value, () => {
        this.segment.origin = value; if (this.originChanged && this.index === 0) {
          this.originChanged.emit(value);
        }
      });
    });

    this.controls[toControlName].valueChanges.subscribe((value: Location) => {
      this.checkWrapper(value, () => this.segment.destination = value);
    });

    this.controls[dateControlName].valueChanges.subscribe((value: Moment) => {
      console.log(value)
      this.checkWrapper(value, () => {
        this.segment.departureDate = value;
      });
    });
  }

  remove($event: MouseEvent) {
    $event.preventDefault();
    if (this.segmentRemoved) {
      this.segmentRemoved.emit(this.segment);
    }
  }
}
