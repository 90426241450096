import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field'
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
//import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
const appearance: MatFormFieldDefaultOptions = {
    appearance: 'outline'
};

@NgModule({
    imports: [
        MatExpansionModule,
        MatAutocompleteModule,
        MatInputModule,
        MatFormFieldModule,
        MatTabsModule,
        MatDatepickerModule,
        MatIconModule,
        MatButtonModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatMomentDateModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatDialogModule
    ],
    exports: [MatExpansionModule,
        MatAutocompleteModule,
        MatInputModule,
        MatFormFieldModule,
        MatTabsModule,
        MatDatepickerModule,
        MatIconModule,
        MatButtonModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatMomentDateModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatDialogModule
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: appearance
        }
    ]
})
export class MaterialsModule { }
